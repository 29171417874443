import { useTypedLazyQuery } from '@/hooks/useZeus';
import React, { useEffect } from 'react';

type WhiteLabelContextProps = {
    loading?:boolean
    whiteLabelKey?:string|null
    whitelabelWorkspace?:{
        id:string
        avatar?:string
        name:string
    }
}

const WhiteLabelContext = React.createContext<WhiteLabelContextProps>({})   

export default function WhiteLabelProvider({
  children
}:{
    children:React.ReactNode
}) {

  const searchParams = new URLSearchParams(window.location.search);

  const whiteLabelKey = searchParams.get('whiteLabelMode') 
  const [getWorkspace, {data,loading}] = useTypedLazyQuery({
    workspace:[{
      where:{
        key: whiteLabelKey
      }
    },{
      avatar:true,
      id:true,
      name:true,
    }]
  });

  useEffect(()=>{
    if(whiteLabelKey){
      getWorkspace();
      localStorage.setItem('whiteLabelKey', whiteLabelKey)
    }
  },[whiteLabelKey])

  return (
    <WhiteLabelContext.Provider value={{
      whiteLabelKey,
      loading,
      whitelabelWorkspace:data?.workspace
    }}>
      {children}
    </WhiteLabelContext.Provider>
  )
}

export const useWhiteLabel = () => React.useContext(WhiteLabelContext)