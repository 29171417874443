import React, { ReactElement } from 'react'
import SectionGridFilterCard from './SectionGridFilterCard'
import { Dialog } from '@/components/Dialog/Dialog';
import ButtonSecondary from '@/shared/Button/ButtonSecondary';
import { AssetFile } from '../CheckOutPage/types';

export default function MediaLibraryPicker(props:{children?:ReactElement,onCardSelect:(file:AssetFile)=>void,close?:()=>void}) {
  return (

    <Dialog header={"Media library"}>
      <Dialog.Trigger>
        {props.children ? props.children: <ButtonSecondary className='mt-4'>Choose from media library</ButtonSecondary>}
      </Dialog.Trigger>
      <Dialog.Body>
        {({closeDialog})=><SectionGridFilterCard className="pb-24 mt-8 lg:pb-28" onCardSelect={(file)=>{closeDialog?.();props.onCardSelect(file);}}/>}
        
      </Dialog.Body>
    </Dialog>
    
  )
}
