
import useUserContext from "@/hooks/useUserContext";
import { useTypedLazyQuery } from "@/hooks/useZeus"
import { useEffect } from "react";
import { useParams } from "react-router-dom"

export function useBookingDetails(){
  const { id } = useParams<{ id: string }>();
  const {user} = useUserContext();

  const [getBooking,{data:bookingDetails,refetch,loading}] = useTypedLazyQuery({
    booking:[{
      where:{
        id
      }
    },{
      id:true,
      paymentStatus:true,
      bookingStatus:true,
      priceSummary:{
        rentPerDay:true,
        days:true,
        payableRent:true,
        serviceCharge:true,
        totalRent:true,
        discount:true,
      },
      proofs:[{},{
        url:true
      }],
      selectedDates:{
        startDate:true,endDate:true
      },
      assets:[{},{
        url:true,
        type:true,
        status:true
      }],
      bookingDate:true,
      bookingCode:true,
      adBoard:{
        id:true,
        title:true,
        resolution:{
          width:true,
          height:true
        },
        screenSize:{
          width:true,
          height:true,
          unit:true
        },
        city:true,
      },
      payment:{
        totalDetails:{
          total:true,
          subtotal:true,
          tax:true,
          discount:true,
        }
      }
    }]
  },{
    notifyOnNetworkStatusChange:true,
    variables:{ 
      getBookingWhere2:{
        id
      }
    }
  })

  useEffect(()=>{
    if(user?.uid){
      getBooking()
    }
  },[user])

  return {   
    loading,
    refetch,
    bookingDetails,
  }
}

export type BookingFragment = NonNullable<ReturnType<typeof useBookingDetails>['bookingDetails']>['booking']