import Box from '@/components/Box'
import { Badge } from '@/components/ui/badge'
import { BookingStatus } from '@/generated/zeus'
import { Info } from 'lucide-react'
import React from 'react'

export default function BookingBox({
  booking,
  index,
  assets
}: {
  index: number,
  assets: {
    name: string
  }[]
  booking: {
    bookingStatus:BookingStatus,
    priceSummary?: {
      payableRent: number
    },
    id: string,
    adBoard: {
      title: string,
      type?: {
        name: string
      },
      screenSize?: {
        width: number,
        height: number
      },
      workspace: {
        name: string
      }
    },
  }
}) {
  return (
    <Box key={booking.id} >
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="font-semibold">Booking #{index + 1}</h3>
          <p className="text-gray-600">{booking.adBoard.title}</p>
        </div>
        
        <Badge
          variant="outline"
          className="bg-yellow-50 text-yellow-700"
        >
          {booking.bookingStatus == BookingStatus.ENQUIRY ? 'Pending approval':booking.bookingStatus}
        </Badge>
      </div>

      <div className="grid md:grid-cols-3 gap-4 mb-4">
        <div>
          <p className="text-sm text-gray-600">Adboard type</p>
          <p>{booking.adBoard.type?.name}</p>
        </div>
        {/* <div>
          <p className="text-sm text-gray-600">Size</p>
          <p>{booking.adBoard.screenSize?.width} x {booking.adBoard.screenSize?.height} ft</p>
        </div> */}
        <div>
          <p className="text-sm text-gray-600">Amount</p>
          <p>₹{booking.priceSummary?.payableRent}</p>
        </div>
      </div>

      <div className="mb-4">
        <p className="text-sm text-gray-600 mb-2">Assigned Creatives</p>
        <div className="flex gap-2">
          {assets.map((creative, idx) => (
            <Badge key={idx} variant="secondary">
              {creative.name}
            </Badge>
          ))}
        </div>
      </div>
      {booking.bookingStatus == BookingStatus.ENQUIRY &&
      <div className="flex items-center gap-2 text-sm text-gray-600">
        <Info className="h-4 w-4" />
        <p>Awaiting approval from {booking.adBoard.workspace.name}</p>
      </div>
      }
    </Box>
  )
}
