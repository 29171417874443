
export default function AssetPreview({ className,url,type,name ,alt}: {alt?:string,className?:string,url: string | null,type?:string,name?:string}) {

  if (!url) return null;

  className = className+" object-cover h-full w-full"

  return < >
    {(type === 'video') ? <video className={className} src={url} controls></video> : <img className={className} src={url} alt={alt||name} />}
  </>;

}
