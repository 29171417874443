import { DATE_FORMAT } from "@/contains/contants";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { FocusedInputShape } from "react-dates";
import ButtonSubmit from "./ButtonSubmit";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export type StaySearchFormFields = "location" | "guests" | "dates";

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
  defaultFieldFocus?: StaySearchFormFields;
}

const StaySearchForm: FC<StaySearchFormProps> = ({
  haveDefaultValue = false,
  defaultFieldFocus,
}) => {

  const params = new URLSearchParams(window.location.search);

  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: moment(),
    endDate: moment()
  });
  const [locationInputValue, setLocationInputValue] = useState("");
  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  //

  useEffect(() => {
    if (defaultFieldFocus === "dates") {
      setDateFocused("startDate");
    } else {
      setDateFocused(null);
    }
  }, [defaultFieldFocus]);

  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue({
        startDate: moment(params.get('startDate'), DATE_FORMAT) || null,
        endDate: moment(params.get('endDate'), DATE_FORMAT) || null,
      });
      setLocationInputValue(params.get('location')||"");
    }
  }, []);

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('location', locationInputValue);
  if (dateRangeValue.startDate) {
    searchParams.set('startDate', dateRangeValue.startDate?.format(DATE_FORMAT) as string);
  }
  if (dateRangeValue.endDate) {
    searchParams.set('endDate', dateRangeValue.endDate?.format(DATE_FORMAT) as string);
  }

  const href = '/adboards?'+searchParams.toString();

  const renderForm = () => {
    return (
      <form className="relative flex rounded-full border border-neutral-200 dark:border-neutral-700">
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => {setLocationInputValue(e)}}
          onInputDone={() => setDateFocused("startDate")}
          className="flex-[1]"
          autoFocus={defaultFieldFocus === "location"}
        />
        <StayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onChange={(data) => {
            setDateRangeValue(data);
          }}
          className="flex-[2]"
        />

        <div
          className={`flex text-left justify-end items-center focus:outline-none cursor-pointer nc-hero-field-focused--2"
        }`}
        >
          <div className="pr-2" onClick={(e) => {
            (e.target as HTMLElement).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
          }}>
            <ButtonSubmit onClick={()=>{
              PubSub.publish('START_SEARCH_CLICK_3',null);
            }} href={href} />
          </div>
        </div>
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
