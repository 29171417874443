import React, { FC, useEffect } from "react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useCartContext } from '@/context/CartContext';
import { useTypedQuery } from '@/hooks/useZeus';
import { fireEventOnce, getDateString, mapAdboardToGtagItem } from '@/lib/utils';
import Loader from "@/shared/FullScreenLoader/Loading";
import {
  AlertCircle,
  Clock
} from "lucide-react";
import { Link, useParams, useSearchParams } from 'react-router-dom';
import AssetBox from "./AssetBox";
import BookingBox from "./BookingBox";

export interface PayPageProps {
  className?: string;
}

const PayDoneCampaignPage: FC<PayPageProps> = ({ className = "" }) => {

  const [searchParams] = useSearchParams();
  const { clearCart } = useCartContext()
  const params = useParams();
  const campaignId = searchParams.get('campaignId') || params.campaignId;

  const { data } = useTypedQuery({
    campaign: [{
      where: {
        id: campaignId
      }
    }, {
      name: true,
      assets: [{}, {
        id: true,
        url: true,
        meta: {
          width: true,
          height: true
        },
        size: true,
        type: true,
        name: true
      }],
      user: {
        email: true,
      },
      priceSummary: {
        discount: true,
        days: true,
        serviceCharge: true,
        payableRent: true,
        rentPerDay: true,
        totalRent: true,
      },
      id: true,
      selectedDates: {
        startDate: true,
        endDate: true
      },
      bookings: [{}, {
        id: true,
        bookingStatus:true,
        priceSummary: {
          payableRent: true,
        },
        adBoard: {
          workspace: {
            name: true,
          },
          screenSize: {
            width: true,
            height: true
          },
          id: true,
          type: [{}, {
            name: true
          }],
          pricePerDay: true,
          city: true,
          galleryImgs: true,
          title: true
        }
      }]
    }]
  })

  useEffect(() => {
    if (data?.campaign?.id && data.campaign) {
      fireEventOnce("purchase", {
        ecommerce: {
          transaction_id: data.campaign.id,
          value: data.campaign.priceSummary?.payableRent,
          tax: data.campaign.priceSummary?.serviceCharge,
          currency: "INR",
          items: data.campaign.bookings.map(booking =>
            mapAdboardToGtagItem(booking.adBoard, data.campaign?.selectedDates as any)
          )
        }
      });
    }
  }, [data?.campaign])

  React.useEffect(() => {
    clearCart()
  }, [])

  if (!data || !data.campaign) {
    return <Loader fullScreen>Loading campaign...</Loader>
  }

  // const {bookings,selectedDates,assets} = data.campaign;

  const BookingConfirmation = () => {

    const campaignDetails = data.campaign;

    if (!campaignDetails) {
      return <Loader fullScreen />
    }

    return (
      <div className="container mx-auto px-4 py-8 max-w-5xl">
        <Card className="p-8">
          {/* Header Section */}
          <div className="text-center mb-8">
            <div className="flex justify-center mb-4">
              <Clock className="h-12 w-12 text-yellow-500" />
            </div>
            <h1 className="text-2xl font-bold mb-2">Successfully submitted for approval</h1>
            <p className="text-gray-600">Campaign ID: {campaignDetails.id}</p>
          </div>

          {/* Campaign Summary */}
          <Card className="p-6 bg-blue-50 mb-8">
            <div className="grid md:grid-cols-3 gap-6">
              <div>
                <p className="text-sm text-gray-600">Campaign Name</p>
                <p className="font-semibold">{campaignDetails.name}</p>
              </div>
              <div className="lg:text-center">
                <p className="text-sm text-gray-600">Duration</p>
                <p className="font-semibold">{getDateString(campaignDetails.selectedDates.startDate)} to {getDateString(campaignDetails.selectedDates.endDate)}</p>
              </div>
              <div className="lg:text-right">
                <p className="text-sm text-gray-600">Total Amount</p>
                <p className="font-semibold">₹{campaignDetails.priceSummary?.totalRent}</p>
              </div>
            </div>
          </Card>

          <div className="space-y-4 mb-8">
            {campaignDetails.bookings.map((booking, index) => (
              <BookingBox key={booking.id} booking={booking} index={index} assets={campaignDetails.assets} />
            ))}
          </div>
          {/* Next Steps */}
          <Alert className="mb-8 bg-blue-50">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription className="ml-2">
              Each billboard booking requires separate approval from its owner. You'll be notified as approvals are received.
            </AlertDescription>
          </Alert>

          <div className="grid md:grid-cols-1 gap-4 mb-4">
            <h2 className="text-xl font-semibold">Creatives</h2>
            {campaignDetails.assets.map((creative) => (
              <AssetBox creative={creative} />
            ))}
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Next Steps</h2>
            <ol className="space-y-4">
              <li className="flex items-center gap-3">
                <Badge variant="outline" className="bg-yellow-50">1</Badge>
                <span>Wait for billboard owners' approvals (typically within 24 hours)</span>
              </li>
              <li className="flex items-center gap-3">
                <Badge variant="outline" className="bg-gray-50">2</Badge>
                <span>Complete payment for approved bookings</span>
              </li>
              <li className="flex items-center gap-3">
                <Badge variant="outline" className="bg-gray-50">3</Badge>
                <span>Receive final campaign confirmation</span>
              </li>
            </ol>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 mt-8">
            <Link to={"/campaigns/" + data.campaign?.id}>
              <Button className="flex-1">
                View campaign
              </Button>
            </Link>
            <Link to="/contact" className="flex-1">
              <Button className="flex-1" variant="outline">
                Contact Support
              </Button>
            </Link>
          </div>

          {/* Support Information */}
          <div className="mt-8 text-center text-sm text-gray-600">
            <p>Need help? Contact our support team</p>
            <p>ankur.kus1@adboardbooking.com | +91 9310854859</p>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">

      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">
          {/* <BookingConfirmation campaign={data.campaign}></BookingConfirmation> */}
          {BookingConfirmation()}
        </div>
      </main>
    </div>
  );
};

export default PayDoneCampaignPage;
