import useUserContext from "@/hooks/useUserContext";
import { PathName } from "@/routers/types";
import MenuBar from "@/shared/MenuBar/MenuBar";
import isInViewport from "@/utils/isInViewport";
import {
  MagnifyingGlassIcon,
  ChatBubbleLeftRightIcon,
  UserCircleIcon
} from "@heroicons/react/24/outline";
import { Home } from "lucide-react";
import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: PathName;
  onClick?: () => void,
  icon: any;
}

const NAV: NavItem[] = [
  {
    name: "Home",
    link: "/",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Log in",
    link: '/login',
    icon: UserCircleIcon,
  },
  // {
  //   name: "Menu",
  //   icon: MenuBar,
  // },
];

const NAV_LOGGED_USER: NavItem[] = [
  {
    name: "Home",
    link: "/",
    icon: Home,
  },
  {
    name: "Enquiries",
    link: "/enquiries",
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: "Bookings",
    link: "/bookings",
    icon: UserCircleIcon,
  },
  {
    name: "Menu",
    icon: MenuBar,
  },
];

const FooterNav = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { user } = useUserContext();

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    const currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
      document.getElementsByClassName('mobile-sticky-footer')[0]?.classList.remove('shift-up');
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
      document.getElementsByClassName('mobile-sticky-footer')[0]?.classList.add('shift-up');
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  let navItems = NAV;
  if (user) {
    navItems = NAV_LOGGED_USER;
  }

  return (
    <>  
      <div
        ref={containerRef}
        className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
      >
        <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
          {/* MENU */}
          {navItems.map((item, index) => {
            const active = location.pathname === item.link;
            return item.link ? (
              <Link
                key={index}
                to={item.link}
                className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${active ? "text-neutral-900 dark:text-neutral-100" : ""
                }`}
              >
                <item.icon
                  className={`w-6 h-6 ${active ? "text-red-600" : ""}`}
                />
                <span className="text-[11px] leading-none mt-1">{item.name}</span>
              </Link>
            ) : (
              <div
                key={index}
                className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${active ? "text-neutral-900 dark:text-neutral-100" : ""
                }`}
                onClick={item.onClick}
              >
                <item.icon iconClassName="w-6 h-6" className={``} />
                <span className="text-[11px] leading-none mt-1">{item.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FooterNav;
