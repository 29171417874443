import { adboardFragment as mapFragment } from "@/components/AnyReactComponent/MapMarker";
import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";

export const adboardFragment = Selector('AdBoard')({
  ...mapFragment,
  workspaceId:true,
  acceptedMediaTypes:true,
  map:{
    lat:true,
    lng:true
  },
  players:[{},{
    id:true,
    resolution:{
      width:true,
      height:true
    },
    map:{
      lat:true,
      lng:true
    }
  }]
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adboardFragment>;