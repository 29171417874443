import useUserContext from "@/hooks/useUserContext"
import { mutation, useTypedQuery } from "@/hooks/useZeus"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import Ratings from "./Ratings"
import Loader from "@/shared/FullScreenLoader/Loading"

export function AddReviewDialog({
  bookingId,
  adBoardId,
  closeDialog
}:{
  adBoardId:string
  bookingId:string,
  closeDialog?:()=>void
}){

  const form = useForm<{
    title:string;
    description:string;
    rating:number;
    confirm:boolean
  }>()

  const {user} = useUserContext();

  const {data:adBoardPublicReviewsData,loading} = useTypedQuery({
    adBoardPublicReviews:[{
      where:{
        bookingId:{
          equals:bookingId
        },
      }
    },{
      id:true
    }]
  })

  async function handleClickSubmit(){
    const data = form.getValues();
    closeDialog?.()
    await mutation({
      createOneAdboardPublicReview:[{
        data:{
          title:data.title,
          description:data.description,
          rating:data.rating,
          isVerified:true,
          user:{
            connect:{
              id:user?.id
            }
          },
          booking:{
            connect:{
              id:bookingId
            } 
          },
          adBoard:{
            connect:{
              id:adBoardId
            }
          }
        }
      },{
        id:true
      }]
    }).catch((e)=>{
      toast.error('Failed to add review')
      throw e
    })
    toast.success('Review added successfully')
    closeDialog?.()
  }

  if(loading){
    return <Loader></Loader>
  }

  if(adBoardPublicReviewsData?.adBoardPublicReviews.length){
    return <div className="left-0 right-0 z-50 h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0 antialiased">
      <div className="relative max-h-full w-full  p-4">
        <div className="relative rounded-lg bg-white dark:bg-gray-800">
          <div className="p-4 md:p-5">
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">You have already added a review for this booking</h2>
              </div>
              <div className="col-span-2">
                <button onClick={closeDialog} type="button" data-modal-toggle="review-modal"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  }

  return  <div className="left-0 right-0 z-50 h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0 antialiased">
    <div className="relative max-h-full w-full  p-4">
      <div className="relative rounded-lg bg-white dark:bg-gray-800">
        <div className="p-4 md:p-5">
          <div className="mb-4 grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <Ratings onChange={(rating)=>{
                form.setValue('rating',rating)
              }}></Ratings>
            </div>
            <div className="col-span-2">
              <label htmlFor="title" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Review title</label>
              <input {...form.register('title',{required:true})} type="text" name="title" id="title" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500" required />
            </div>
            <div className="col-span-2">
              <label htmlFor="description" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Review description</label>
              <textarea {...form.register('description',{required:true})} id="description" rows={6} className="mb-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500" required></textarea>
              <p className="ms-auto text-xs text-gray-500 dark:text-gray-400">Problems with the product or delivery? <a href="#" className="text-primary-600 hover:underline dark:text-primary-500">Send a report</a>.</p>
            </div>
            <div className="col-span-2">
              <p className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Add real photos of the product to help other customers <span className="text-gray-500 dark:text-gray-400">(Optional)</span></p>
              <div className="flex w-full items-center justify-center">
                <label htmlFor="dropzone-file" className="dark:hover:bg-bray-800 flex h-52 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pb-6 pt-5">
                    <svg className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="dropzone-file" type="file" className="hidden" />
                </label>
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex items-center">
                <input id="review-checkbox" {...form.register('confirm',{required:true})} type="checkbox" value="" className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-primary-600 focus:ring-2 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600" />
                <label htmlFor="review-checkbox" className="ms-2 text-sm font-medium text-gray-500 dark:text-gray-400">By publishing this review you agree with the <a href="#" className="text-primary-600 hover:underline dark:text-primary-500">terms and conditions</a>.</label>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 pt-4 dark:border-gray-700 md:pt-5">
            <button onClick={handleClickSubmit} className="me-2 inline-flex items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Add review</button>
            <button onClick={closeDialog} type="button" data-modal-toggle="review-modal" className="me-2 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
}