import BackgroundSection from "@/components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import SectionSliderNewCategories from "@/components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";
import { $, AdBoardStatus, WorkspaceType } from "@/generated/zeus";
import useUserContext from "@/hooks/useUserContext";
import { useTypedLazyQuery } from "@/hooks/useZeus";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";
import { AdboardPageProvider } from "./ListingPageContext";
import SectionGridHasMap from "./SectionGridHasMap";
import { adBoardSelector } from "./types";
import { baseTitle } from "@/contains/contants";
import { fireEvent, setPageTitle } from "@/lib/utils";
import { Bounds } from "google-map-react";
import { useWhiteLabel } from "@/context/WhiteLabelProvider";

export interface ListingStayMapPageProps {
  className?: string;
}

const AdboardMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => { 
  const {config} = useUserContext()
  const {location:locationParam} = useParams();
  const {whiteLabelKey} = useWhiteLabel()
  const [searchChange, setSearchChange] = React.useState(false);

  const [fetchAdboards,{data,loading,previousData}] = useTypedLazyQuery({
    adBoards:[{
      where:$('where','AdBoardWhereInput')
    },adBoardSelector]
  },{
    notifyOnNetworkStatusChange:false,
    fetchPolicy:'network-only'
  })
  
  const enabledAdboards = data? data?.adBoards:previousData?.adBoards;
  
  const [searchParams] = useSearchParams();
  const location = searchParams.get('location')||locationParam; 
  const rangePrices = JSON.parse(searchParams.get('rangePrices') as string)||[]; 
 
  type FetchAdboardType = Parameters<typeof fetchAdboards>[0]
  function getAdboardsSelector(){
    const selector: FetchAdboardType={
      variables:{
        where:{
          
          workspace:{
            is:{
              key: whiteLabelKey ? {
                equals:whiteLabelKey
              }:undefined,
              type:{
                in:[config?.env == 'production' ? WorkspaceType.PROD : WorkspaceType.TEST] 
              }
            }
          },
          type:searchParams.get('adBoardTypes') ? {
            is:{
              id:{
                in:searchParams.get('adBoardTypes')?.split(",")||undefined
              }
            }
          }:undefined,
          isLive:{
            equals:true
          },
          status:{
            notIn:[AdBoardStatus.ARCHIVED]
          },
          city:(location && location!="All")? {
            equals:location
          }:undefined,
          
          AND:[
            {
              pricePerDay:{
                gte:rangePrices[0]||0
              }
            },
            {
              pricePerDay:{
                lte:rangePrices[1]||1000000
              }
            }
            
          ],
          
        }
      }
    }
    return selector;
  }

  useEffect(()=>{
    setSearchChange(true);
    fetchAdboards(getAdboardsSelector()).then(data=>{
      fireEvent('view_item_list',{
        ecommerce:{
          item_list_name: "Adboards in "+location,
          currency: "INR",
          items:data.data?.adBoards.map(item=>{
            return {
              item_id: item.id,
              item_name: item.title,
              item_category: item.city,
              price: item.pricePerDay,
              quantity: 1
            }
            
          })
        }})
    })
    setPageTitle(`Digital billboards in ${location} | ${baseTitle}`)
  },[searchParams] )

  function handleMapDragged(bounds:Bounds){

    const data = getAdboardsSelector();
    data.variables=data.variables||{};
    data.variables.where.city = undefined;
    data.variables.where.players = {
      some:{
        map:{
          is:{
            lat:{
              gte:bounds.sw.lat,
              lte:bounds.ne.lat
            },
            lng:{
              gte:bounds.sw.lng,
              lte:bounds.ne.lng
            }
          }
        }
      }
    }

    fetchAdboards(data)
  }
  
  return (
    <>
      <div
        className={`nc-ListingStayMapPage relative ${className}`}
        data-nc-id="ListingStayMapPage"
      >
        <Helmet> 
          <title>{`Digital billboards in ${location} | ${baseTitle}`}</title>
          <meta property="og:description"
            content={`Explore our extensive inventory of digital billboards across ${location}. Find and book the perfect outdoor advertising space for your campaign with AdboardBooking.`} />
          <link rel="canonical" href={`https://www.adboardbooking.com/billboards/${location}`} />
        </Helmet>
        <BgGlassmorphism />

        {/* SECTION HERO */}
        {/* <div className="container pt-0 lg:pt-16 pb-10">
          <SectionHeroArchivePage 
            adboadCount={enabledAdboards?.length}
            city={location||"All location"}
            currentPage="Stays" 
            currentTab="Stays" />
        </div> */}

        {/* SECTION */}
        <div id="listing-section" className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
          <SectionGridHasMap hasSearchChanged={searchChange} onMapDragged={handleMapDragged} loading={loading} adboards = {enabledAdboards||[]}/>
        </div>

        <div className="container overflow-hidden">
          
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore top locations..."
              subHeading="Explore thousands of adboards within our country"
              categoryCardType="card4"
              itemPerRow={4}
              sliderStyle="style2"
              uniqueClassName="ListingExperiencesPage"
            />
          </div>

          {/* SECTION */}
          <SectionSubscribe2 className="py-24 lg:py-28" />
         
        </div>
      </div>
    </>
  );
};

export default function(){
  return <AdboardPageProvider>
    <AdboardMapPage/>
  </AdboardPageProvider>
}
