import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";
import { adboardFragment as mapFragment } from "@/components/AnyReactComponent/MapMarker";
import { adBoardSelector as cardFragment } from "@/components/PropertyCardH/PropertyCardH";
import { adboardFragment as stayCardHFragment } from "@/components/StayCardH/StayCardH";

export const adBoardSelector = Selector('AdBoard')({
  ...mapFragment,
  ...cardFragment,
  ...stayCardHFragment,
  href: true,
  players:[{},{
    id:true,
    map:{
      lat:true,
      lng:true
    }
  }]
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adBoardSelector>;