import BgGlassmorphism from '@/components/BgGlassmorphism/BgGlassmorphism';
import React from 'react';
import { Helmet } from 'react-helmet';

const PartnerSolutions = () => {
  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Partner with AdBoardBooking | AdBoardBooking</title>
        <meta name="description" content="Partner with AdBoardBooking to revolutionize digital advertising. Access a wide network of digital billboards, comprehensive media management tools, and dedicated support." />
        <link rel="canonical" href="https://www.adboardbooking.com/partner" />
      </Helmet>
      <BgGlassmorphism />

      <div className="container mt-8 relative space-y-10 mb-24 lg:space-y-28 lg:mb-28">
        <div className="font-sans">
          {/* Hero Section */}
          <section className="bg-blue-600 text-white text-center py-20">
            <h1 className="text-4xl font-bold">Partner with AdBoardBooking</h1>
            <p className="mt-4 text-lg">Join us in revolutionizing digital advertising.</p>
          </section>

          {/* Benefits Section */}
          <section className="py-16 px-4 md:px-8 lg:px-16">
            <h2 className="text-3xl font-semibold text-center">Benefits of Partnering</h2>
            <ul className="mt-8 space-y-4">
              <li className="flex items-center">
                <span className="bg-blue-600 text-white rounded-full p-2 mr-4">✔️</span>
                Access to a wide network of digital billboards, increasing your reach and visibility.
              </li>
              <li className="flex items-center">
                <span className="bg-blue-600 text-white rounded-full p-2 mr-4">✔️</span>
                Comprehensive media management tools to streamline your operations.
              </li>
              <li className="flex items-center">
                <span className="bg-blue-600 text-white rounded-full p-2 mr-4">✔️</span>
                Dedicated support and resources to help you succeed.
              </li>
              <li className="flex items-center">
                <span className="bg-blue-600 text-white rounded-full p-2 mr-4">✔️</span>
                Enhanced analytics and reporting to track your performance.
              </li>
              <li className="flex items-center">
                <span className="bg-blue-600 text-white rounded-full p-2 mr-4">✔️</span>
                Flexible pricing models to suit your business needs.
              </li>
            </ul>
          </section>

          {/* How It Works Section */}
          <section className="bg-gray-100 py-16 px-4 md:px-8 lg:px-16">
            <h2 className="text-3xl font-semibold text-center">How It Works</h2>
            <ol className="mt-8 space-y-4 list-decimal list-inside">
              <li>Sign up as a partner through our easy registration process.</li>
              <li>List your digital signage assets on our platform.</li>
              <li>Manage bookings and content seamlessly using our intuitive interface.</li>
              <li>Receive detailed analytics and reports to monitor your performance.</li>
              <li>Get dedicated support from our team to ensure your success.</li>
            </ol>
          </section>

          {/* Testimonials Section */}
          <section className="py-16 px-4 md:px-8 lg:px-16">
            <h2 className="text-3xl font-semibold text-center">What Our Partners Say</h2>
            <div className="mt-8 space-y-8">
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <p className="text-lg italic">"AdBoardBooking has transformed the way we manage our digital signage. The platform is intuitive and the support team is exceptional."</p>
                <p className="mt-4 text-right font-semibold">- Alex Johnson, Media Manager at BrightAds</p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <p className="text-lg italic">"Partnering with AdBoardBooking has expanded our reach and streamlined our operations. Highly recommend their services."</p>
                <p className="mt-4 text-right font-semibold">- Maria Rodriguez, CEO of Urban Screens</p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <p className="text-lg italic">"The analytics and reporting tools provided by AdBoardBooking have given us valuable insights into our campaigns."</p>
                <p className="mt-4 text-right font-semibold">- John Smith, Marketing Director at DigitalBillboards Inc.</p>
              </div>
            </div>
          </section>

          {/* Call to Action Section */}
          <section className="bg-blue-600 text-white text-center py-16">
            <h2 className="text-3xl font-semibold">Become a Partner Today</h2>
            <p className="mt-4 text-lg">Join our network and take advantage of our comprehensive digital advertising solutions.</p>
            <button
              onClick={() => window.location.href='/contact'}
              className="mt-8 bg-white text-blue-600 font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300"
            >
              Contact Us
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PartnerSolutions;