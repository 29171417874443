import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { ReactNode, useEffect } from 'react';

export default function Box(props: {
  classname?: string,
  collapsible?: boolean,
  collapsed?: boolean,
  title?: ReactNode,
  children?: React.ReactNode,
  buttons?: React.ReactNode,
  onCollapseChange?: (collapsed: boolean) => void
}) {
  const [collapsed, setCollapsed] = React.useState(props.collapsed);

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])

  function handleHeaderClick() {
    if (props.collapsible) {
      setCollapsed(!collapsed)
      if (props.onCollapseChange) {
        props.onCollapseChange(!collapsed)
      }
    }
  }

  return (
    <div className="bg-white/80 transition-all duration-200  dark:bg-black w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 py-4 xl:p-6 xl:py-6 shadow-lg p-2 rounded-lg">
      <div className='cursor-pointer flex justify-between items-center' onClick={handleHeaderClick}>
        <div >
          {props.title &&
            <h2 className="text-2xl font-semibold cursor-pointer" >
              {props.title}
            </h2>
          }
        </div>
        <div>
          {props.buttons}
          {props.collapsible &&
            <span onClick={() => setCollapsed(!collapsed)} className='cursor-pointer'>{collapsed ?
              <ChevronDownIcon className='w-4 h-4' /> : <ChevronDownIcon className='w-4 h-4 transform rotate-180' />
            }</span>
          }
        </div>

      </div>

      <div className={' '+(collapsed?'h-0 pt-0':"h-full pt-2")+' overflow-hidden transition-all duration-200 ' + props.classname}>
        {props.children}
      </div>
      
    </div>
  )
}
