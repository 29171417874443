import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { DateRage } from "@/components/HeroSearchForm/StaySearchForm";
import React, { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
import { useCartContext } from "@/context/CartContext";
import { fireEvent, mapAdboardToGtagItem } from "@/lib/utils";
import { AdboardFragment } from "./types";
import { WorkspaceType } from "@/generated/zeus";
import { DATE_FORMAT } from "@/contains/contants";

type PriceSummary = {
  rentPerDay: number | undefined;
  totalRent: number;
  days: number;
  serviceCharge: number;
  payableRent: number;
}

interface ModalReserveMobileProps {
  onClose?: () => void;
  renderChildren?: (p: { openModal: (id:string) => void }) => React.ReactNode;
  priceSummary: PriceSummary,
  listingDetails:AdboardFragment,
  selectedDate:DateRage,
  renderModalContent?: () => React.ReactNode;
}

const ModalReserveMobile: FC<ModalReserveMobileProps> = ({
  listingDetails,
  selectedDate,
  renderModalContent,
  renderChildren,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [orderId,setOrderId] = useState<string>("");
  const navigate = useNavigate();
  const {adBoards,toggleFromCart} = useCartContext();
  const isCartAdded = adBoards.some((adBoard)=>adBoard.id === listingDetails?.id)
  const [loading] = React.useState(false)
  // const [slot,setSlot] = React.useState<number>()
  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal(id:string) {
    setOrderId(id)
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={()=>openModal(orderId)}>Select Date</button>
    );
  };

  async function handleClickReserve(){
    navigate('/campaigns/new?adboardId='+listingDetails.id+'&startDate='+selectedDate.startDate?.format(DATE_FORMAT)+'&endDate='+selectedDate.endDate?.format(DATE_FORMAT))
  }

  if(!listingDetails || !listingDetails.id){
    return null;
  }

  function handleToggleCart(){
    listingDetails?.id && toggleFromCart(listingDetails?.id)
    const {startDate,endDate} =selectedDate;
    
    if(listingDetails && startDate && endDate){
      fireEvent('add_to_cart',{
        ecommerce: {
          currency: "INR",
          value: listingDetails.pricePerDay,
          items: [
            mapAdboardToGtagItem(listingDetails,{
              startDate:startDate.toISOString(),
              endDate:endDate.toISOString(),
            })
          ]
        }
      })
    }
  }

  const isBookingDisabled = (listingDetails.workspace.type == WorkspaceType.TEST && window.location.hostname == 'www.adboardbooking.com');

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full flex-1 flex flex-col justify-between overflow-auto">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 py-1 flex flex-col ">
                      <div className="flex-1 bg-white dark:bg-neutral-900 p-4">
                        <div className="flex flex-col relative h-full justify-between">

                          {/* <SlotSelector adBoardId={listingDetails.id} selectedDate={selectedDate} onSlotSelection={(slot?:Slot)=>setSlot(slot?.value)}/> */}
                          <div>
                            {renderModalContent?.()}
                          </div>
                          <div className="sticky bottom-0"  >
                            {/* <ButtonSecondary
                              loading = {loading}
                              sizeClass="px-5 py-3 mb-2 !rounded-2xl w-full"
                              onClick={handleAddToCart}
                            >
                          Add to cart
                            </ButtonSecondary> */}
                            {isCartAdded?<ButtonSecondary sizeClass="px-5 py-3 mb-2 !rounded-2xl w-full" loading = {loading} onClick={()=>handleToggleCart()}>Added to cart
                              <CheckIcon className="ml-2 w-4 h-4 font-bold" />
                            </ButtonSecondary>:
                              <ButtonSecondary sizeClass="px-5 py-3 mb-2 !rounded-2xl w-full" loading = {loading} onClick={(e)=>{
                                e.preventDefault();
                                handleToggleCart()}}>Add to cart</ButtonSecondary>
                            }
                            <ButtonPrimary
                              disabled = {isBookingDisabled}
                              loading = {loading}
                              sizeClass="px-5 py-3 !rounded-2xl w-full"
                              onClick={()=>handleClickReserve()}
                            >
                            Create new campaign
                            </ButtonPrimary>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalReserveMobile;
