// import { Dialog } from '@/components/Dialog/Dialog'
import { useTypedQuery } from '@/hooks/useZeus'
import { getDateTimeString } from '@/lib/utils';
import React, { useEffect, useState } from 'react'

type Review={
  averageReview: number;
  reviewCounts: number[];
  totalReview: number;
  reviews: {
      rating: number;
      title: string;
      description: string;
      user: {
          displayName: string;
      };
      imageUrls: string[];
      createdDate: string;
  }[];
}

export default function CommentsSection({
  adBoardId
}:{
  adBoardId:string
}) {

  const [reviewData,setReviewData] = useState<Review>();
  
  const {data} = useTypedQuery({
    adBoardPublicReviews:[{
      where:{
        adBoardId:{
          equals:adBoardId
        }
      }
    },{
      user:{
        displayName:true
      },
      imageUrls:true,
      title:true,
      description:true,
      rating:true,
      createdAt:true
    }]
  })

  useEffect(()=>{

    if(!data?.adBoardPublicReviews.length){
      return
    }

    let reviewSumCount = 0;
    const reviewCounts = [0,0,0,0,0]
    for(let i=0;i<data.adBoardPublicReviews.length;i++){
      reviewCounts[data.adBoardPublicReviews[i].rating-1]++
      reviewSumCount += data.adBoardPublicReviews[i].rating
    }

    const averageReview  = reviewSumCount/data.adBoardPublicReviews.length

    setReviewData({
      averageReview:averageReview,
      reviewCounts,
      totalReview: data.adBoardPublicReviews.length,
      reviews:data.adBoardPublicReviews.map(item=>{
        return {  
          rating:item.rating,
          title:item.title,
          description:item.description,
          user:{
            displayName:item.user.displayName||""
          },
          imageUrls: item.imageUrls,
          createdDate:item.createdAt
        }
      })
    })
  },[data])

  if(!reviewData){
    return <></>
  }

  return (
    <>
      <section className="bg-white py-2 antialiased dark:bg-gray-900 md:py-2">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
          <div className="flex items-center gap-2">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Reviews</h2>

            <div className="mt-2 flex items-center gap-2 sm:mt-0">
              <div className="flex items-center gap-0.5">
                {Array(Math.round(reviewData.averageReview)).fill(0).map(()=>{
                  return  <svg className="h-4 w-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                  </svg>
                })}
                
              </div>
              <p className="text-sm font-medium leading-none text-gray-500 dark:text-gray-400">({reviewData.averageReview})</p>
              <a href="#" className="text-sm font-medium leading-none text-gray-900 underline hover:no-underline dark:text-white"> {reviewData.totalReview} Reviews </a>
            </div>
          </div>

          <div className="my-6 gap-8 sm:flex sm:items-start md:my-8">
            <div className="shrink-0 space-y-4">
              <p className="text-2xl mb-4 font-semibold leading-none text-gray-900 dark:text-white">{reviewData.averageReview} out of 5</p>
              {/* <Dialog header={<>
                <div className="rounded-t border-gray-200 dark:border-gray-700">
                  <div>
                    <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">Add a review for:</h3>
                    <a href="#" className="font-medium text-primary-700 hover:underline dark:text-primary-500">Apple iMac 24" All-In-One Computer, Apple M1, 8GB RAM, 256GB SSD</a>
                  </div>
                  
                </div>
              </>}>
                <Dialog.Trigger>
                  <button type="button" data-modal-target="review-modal" data-modal-toggle="review-modal" className="mb-2 me-2 rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Write a review</button>
                </Dialog.Trigger>
                <Dialog.Body>
                  <ModalBody />
                </Dialog.Body>
              </Dialog> */}
            </div>

            <div className="mt-6 min-w-0 flex-1 space-y-3 sm:mt-0">

              {reviewData.reviewCounts.map((value,index)=>{
                return <div className="flex items-center gap-2">
                  <p className="w-2 shrink-0 text-start text-sm font-medium leading-none text-gray-900 dark:text-white">{index+1}</p>
                  <svg className="h-4 w-4 shrink-0 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                  </svg>
                  <div className="h-1.5 w-80 rounded-full bg-gray-200 dark:bg-gray-700">
                    <div className="h-1.5 rounded-full bg-yellow-300" style={{"width": value*100/reviewData.totalReview+"%"}}></div>
                  </div>
                  <a href="#" className="w-8 shrink-0 text-right text-sm font-medium leading-none text-primary-700 hover:underline dark:text-primary-500 sm:w-auto sm:text-left">{value} <span className="hidden sm:inline">reviews</span></a>
                </div>
              })}
             
            </div>
          </div>

          <div className="mt-6  divide-y divide-gray-200 dark:divide-gray-700">
            
            {reviewData.reviews.map((review)=>{
              return  <div className="gap-3 py-6 sm:flex sm:items-start">
                <div className="shrink-0 space-y-2 sm:w-48 md:w-72">
                  <div className="flex items-center gap-0.5">

                    {Array(review.rating).fill(0).map(()=>{
                      return <svg className="h-4 w-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                    })}
                   
                  </div>

                  <div className="space-y-0.5">
                    <p className="text-base font-semibold text-gray-900 dark:text-white">{review.user.displayName}</p>
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">{getDateTimeString(review.createdDate)}</p>
                  </div>

                  <div className="inline-flex items-center gap-1">
                    <svg className="h-5 w-5 text-primary-700 dark:text-primary-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        fill-rule="evenodd"
                        d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">Verified purchase</p>
                  </div>
                </div>

                <div className="mt-4 min-w-0 flex-1 space-y-2 sm:mt-0">
                  <h3 className="text-base font-semibold text-black">{review.title}</h3>
                  <p className="text-base font-normal text-gray-500 dark:text-gray-400">{review.description}</p>

                  <div className="flex gap-2">
                    {review.imageUrls.map((url,index)=>{
                      return <img key={index} className="h-60 rounded-lg object-cover" src={url} alt="" />
                    })}
                    
                  </div>
                 
                </div>
              </div>
            })}
          
          </div>

          <div className="mt-6 text-center">
            <button type="button" className="mb-2 me-2 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">View more reviews</button>
          </div>
        </div>
      </section>
     
    </>
  )
}
