import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";
import { useWishlistContext } from "@/context/WishlistProvider";
import { useTypedLazyQuery } from "@/hooks/useZeus";
import { Tab } from "@headlessui/react";
import StayCard,{adboardFragment} from "@/components/StayCard/StayCard";
import React, { FC, Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Loader from "@/shared/FullScreenLoader/Loading";

export interface WishlistsPageProps {
  className?: string;
}

const WishlistsPage: FC<WishlistsPageProps> = ({
  className = "",
}) => {

  const [loading, setLoading] = React.useState(true);  

  const {wishlistedAdBoardIds} = useWishlistContext();

  const [fetchAdboards,{data,loading:loadingWishlist}] = useTypedLazyQuery({
    adBoards:[{
      where:{
        id:{
          in: wishlistedAdBoardIds
        }
      }
    },adboardFragment]
  });

  useEffect(()=>{
    if(wishlistedAdBoardIds.length>0){
      setLoading(false);
      fetchAdboards();
    }
  },[wishlistedAdBoardIds])

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Bookings | Adboard Booking</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative pt-4">
        {/* SECTION HERO */}

        <div className="listingSection__wrap">
          <div>
            <h2 className="text-2xl font-semibold">Wishlists</h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              This is your wishlist. You can save your favorite adboards here.
            </span>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <div>
            <Tab.Group>
              <Tab.List className="flex space-x-1 overflow-x-auto">
                
                <Tab key={"Default"} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      Default
                    </button>
                  )}
                </Tab>
               
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="relative">
                  {(loading || loadingWishlist) && <Loader/>}
                  <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-4">
                    {data?.adBoards.map((stay)=>{
                      return  <StayCard section="featured-adboards" target="_self" key={stay.id} data={stay} />
                    })}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
     
        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default WishlistsPage;