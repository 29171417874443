import moment from "moment";
import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { GEO_API_KEY } from "@/contains/contants";

export const vapidKey = 'BAWfmR0Y8U3VzZAnKFzeXGTX6rFZh4TxYVDVW5Yow-j3vc128EqdcZ89SjMYyaPOoig9D8ag1VakXrgmE2XTem8'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatPrice(price?:number){
  if(!price) return "₹0";
  let suffix = "";
  if(price>10000000){
    suffix = "Cr";
    price = price/10000000;
  } 
  else if(price>100000){
    suffix = "L";
    price = price/100000;
  }
  try{
    return (price?.toLocaleString('en-IN', { style: 'currency', currency: 'INR',maximumFractionDigits:0}) || "₹0" )+ suffix;
  }catch(e){
    return (price?.toLocaleString('en-IN', { style: 'currency', currency: 'INR'}) || "₹0" )+ suffix;
  }
}

export function getDisplayName(name:string) {
  if(name.indexOf('||') != -1) {
    return name.split('||')[1];
  }else{
    return name;
  }
}

export function getAssetUrl(fileName?:string){
  if(!fileName) return "";
  return "/asset/" + fileName?.replaceAll(/\./g, "__") || ""
}

export function getDateString(date?:string|unknown) {
  if (!date) {
    return '-';
  }
  return moment(date).format('DD-MMM-YYYY');
}

export function getFileSize(bytes) {
  if (bytes < 1024) {
    return bytes + " B"; // Bytes if less than 1KB
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB"; // Kilobytes if less than 1MB
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB"; // Megabytes otherwise
  }
}

export function shortenPrice(price?:number|string) {
  if(Number(price)>1000){
    return (Number(price)/1000).toFixed(1) + "K";
  }
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getDateTimeString(date?:string) {
  if (!date) {
    return '-';
  }
  return moment(date).format('DD-MMM-YYYY HH:mm:ss');
}

export const cityImageMap = {
  "Gurugram":"https://adboard-booking-cdn.mos.ap-southeast-2.sufybkt.com/adboard-booking-web/istockphoto-1097491472-612x612.jpeg",
  "Pune":"https://adboard-booking-cdn.mos.ap-southeast-2.sufybkt.com/adboard-booking-web/pexels-deepak-shendkar-5732839.jpg",
  "Noida":"https://cdn.pixabay.com/photo/2022/05/23/18/22/ashutosh-kaushik-7216905_1280.jpg",
  "New Delhi":"https://cdn.pixabay.com/photo/2022/04/13/13/56/old-building-7130386_1280.jpg", 
  "Mungra Badshahpur":"https://cdn.pixabay.com/photo/2021/08/04/04/52/bara-imambara-6521064_1280.jpg",
}

export const mapAdboardToGtagItem = (adBoard:{
  id:string,
  title:string,
  city:string,
  pricePerDay:number
}, selectedDates:{startDate:string,endDate:string}) => {
  const days = moment(selectedDates.endDate).diff(moment(selectedDates.startDate), 'days')+1;
  return {
    item_id: adBoard.id,
    item_name: adBoard.title,
    item_address: adBoard.city,
    price: adBoard.pricePerDay,
    quantity:days 
  };
}

/**
 * 
 * @param eventName
 * <widget>.<label>.<action>
 */
export function fireEvent(eventName:string, params?:any){
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': eventName,
    ...params
  });
}

export function setPageTitle(title:string){
  document.title=title;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'spa_page_view',
    pageTitle: title,
    pagePath: window.location.pathname,
  });
}

export function fireEventOnce(eventName:string, params:any){
  if (!sessionStorage.getItem('gtag_'+eventName)) {
    fireEvent(eventName, params);
    sessionStorage.setItem('gtag_'+eventName, 'true');
  }
}

export function titleToUrl(title?:string){
  return title?.replace(/[^a-zA-Z0-9]/g,'-').toLowerCase();
}

export function getLocation(lat, lng) {
  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GEO_API_KEY}&enable_address_descriptor=true`)
    .then(res => res.json()).then(res => {
      const { results } = res;
      const addressComponent = results[0]?.address_components;
      const country = addressComponent.find(a => a.types.includes('country'))?.long_name || "";
      const state = addressComponent.find(a => a.types.includes('administrative_area_level_1'))?.long_name || "";
      const city = addressComponent.find(a => a.types.includes('locality'))?.long_name || "";
      const address = results[0].formatted_address || "";
      return {
        address,
        city,
        state,
        country
      }
    })
}