import React, { TextareaHTMLAttributes } from "react";

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>

const Textarea = React.forwardRef<HTMLTextAreaElement , TextareaProps & {
  label?:string
}>(
  ({ className = "", children,label, ...args }, ref) => {
    return (
      <>
        {label &&
        <label className="block text-neutral-500 dark:text-neutral-400 text-sm font-normal">
          {label}
        </label>
        }
        <textarea
          ref={ref}
          className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${className}`}
          rows={4}
          {...args}
        >
          {children}
        </textarea>
      </>
    );
  }
);

export default Textarea;
