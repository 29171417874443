import { AdBoardStatus, WorkspaceType } from '@/generated/zeus';
import useUserContext from '@/hooks/useUserContext';
import { useTypedQuery } from '@/hooks/useZeus';
import ButtonClose from '@/shared/ButtonClose/ButtonClose';
import Heading from '@/shared/Heading/Heading';
import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { useMap } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import AdboardMap from './AdboardMap';

export default function PageHomeMap() {
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const { config } = useUserContext();
  const map = useMap();
  const [markers] = useState<{[key: string]: Marker}>({});
  const clusterer = useRef<MarkerClusterer | null>(null);

  // Initialize MarkerClusterer, if the map has changed
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({map});
    }
  }, [map]);

  // Update markers, if the markers array has changed
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const { data } = useTypedQuery({
    adBoards: [{
      where: {
        isLive: { equals: true },
        status: { notIn: [AdBoardStatus.ARCHIVED] },
        workspace: {
          is: {
            type: {
              in: [config?.env === 'production' ? WorkspaceType.PROD : WorkspaceType.TEST]
            }
          }
        }
      }
    }, {
      id: true,
      title: true,
      players: [{}, {
        map: {
          lat: true,
          lng: true
        }
      }]
    }]
  });

  const listings = data?.adBoards || [];
 
  return (
    <div className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? 'fixed inset-0 z-50' : 'hidden'}`}>
      <Heading isCenter desc="Discover Our Extensive Network of Digital Display Locations">
        Your Moments, Our Screens, Infinite Reach
      </Heading>

      {showFullMapFixed && (
        <ButtonClose
          onClick={() => setShowFullMapFixed(false)}
          className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
        />
      )}

      <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[600px] rounded-3xl overflow-hidden">
        {listings.length > 0 && (
          <AdboardMap pois={listings.map(item=>{
            return item.players
          }).flat().map((item,i) => {
            return {
              key: i+"",
              location: { lat: item.map?.lat||0, lng: item.map?.lng||0 }
            }
          })} />
           
        )}
      </div>
    </div>
  );
}
