import BookingCard from "@/components/BookingCard/BookingCard";
import Heading2 from "@/components/Heading/Heading2";
import { $, BookingStatus, PaymentStatus, SortOrder } from "@/generated/zeus";
import useUserContext from "@/hooks/useUserContext";
import { useTypedLazyQuery } from "@/hooks/useZeus";
import { fireEvent } from "@/lib/utils";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import BookingStatusFilter from "./BookingStatusFilter";
import { BookingFragment, bookingSelector } from "./types";

const today = moment()

export interface SectionGridFilterCardProps {
  className?: string;
}

type Filters = {
  status: BookingStatus[]
}

const EnquiriesList: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {

  const [filters, setFilters] = useState<Filters>({
    status: []
  });

  function handleSave(value: BookingStatus[]) {
    setFilters({
      ...filters,
      status: value
    })
  }

  const { user } = useUserContext();

  const [getBookings, { data, loading, refetch }] = useTypedLazyQuery({
    bookings: [{
      orderBy: [{
        bookingDate: SortOrder.desc
      }],
      take: $('take', 'Int'),
      where: {

        AND: [{
          OR:[{
            paymentStatus: {
              in: [PaymentStatus.PENDING]
            },
          },{
            paymentStatus: {
              isSet:false
            },
          }]
        },
        { 
          OR: [{
            user: {
              is: {
                uid: {
                  equals: user?.uid
                }
              }
            }
          }, {
            sharedUsers: {
              some: {
                uid: {
                  equals: user?.uid
                }
              }
            }
          }],
        }]
      }
    }, bookingSelector]
  }, {
    variables: {
      take: 10
    },
    notifyOnNetworkStatusChange: true
  })

  function showMore() {
    fireEvent('booking.showMore.click')
    getBookings({
      variables: {
        take: 10 + (data?.bookings?.length || 0)
      }
    })
  }

  useEffect(() => {
    if (user?.uid) {
      getBookings()
    }
  }, [user])

  const [bookings, setBookings] = React.useState<{
    upcomingBookings: BookingFragment[],
    completedBookings: BookingFragment[]
  }>({
    upcomingBookings: [],
    completedBookings: []
  });

  useEffect(() => {
    if (data?.bookings && data?.bookings?.length > 0) {
      const upcomingBookings: any = [];
      const completedBookings: any = [];
      for (const bookingFragment of data.bookings) {
        const booking = bookingFragment;
        if (moment(booking.selectedDates.endDate).diff(today, 'days') >= 0 && booking.bookingStatus != BookingStatus.CANCELLED) {
          upcomingBookings.push(booking);
        } else {
          completedBookings.push(booking);
        }
      }
      setBookings({
        upcomingBookings,
        completedBookings
      })
    }

  }, [data])

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Your enquiries"
        subHeading={<></>}
      />

      <div className="flex flex-col gap-4">

        <BookingStatusFilter onSave={handleSave} />
        <div className="lg:col-span-3">
          <div className="grid grid-cols-1 gap-6 ">
            {!loading && data?.bookings?.length == 0 && <div className="flex mt-12 justify-center items-center">
              <h4 className='text-lg'>
                You have no bookings.
              </h4>
            </div>
            }
            {user?.uid &&
              <>
                {/* <Block title=""> */}
                <div className="flex gap-4 flex-col">
                  {bookings.upcomingBookings?.map((item) => (
                    <BookingCard refresh={refetch} key={item.id} data={item} />
                  ))}
                  {bookings.completedBookings?.map((item) => (
                    <BookingCard refresh={refetch} key={item.id} data={item} />
                  ))}
                </div>

                <div className="flex mt-12 justify-center items-center">
                  <ButtonPrimary loading={loading} onClick={showMore}>{!user || loading ? 'Loading...' : 'Show more'}</ButtonPrimary>
                </div>
                {/* </Block> */}

              </>
            }

          </div>
        </div>

      </div>

    </div>
  );
};

export default EnquiriesList;