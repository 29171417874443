import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";

export const bookingSelector = Selector('Booking')({
  id:true, 
  assets:[{},{
    url:true,
    type:true
  }],
  priceSummary:{
    totalRent:true,
  },
  paymentStatus:true,
  adBoard:{
    title:true,
    city:true,
    pricePerDay:true,
  },
  payment:{
    totalDetails:{
      total:true,
    }
  },
  selectedDates:{
    endDate:true,
    startDate:true
  },
  bookingStatus:true,
});

export type BookingFragment = InputType<GraphQLTypes['Booking'], typeof bookingSelector>;