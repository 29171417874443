import BgGlassmorphism from '@/components/BgGlassmorphism/BgGlassmorphism'
import Box from '@/components/Box'
import { DialogBody } from "@/components/Dialog/Dialog"
import Heading from '@/components/Heading/Heading'
import { DateRage } from '@/components/HeroSearchForm/StaySearchForm'
import { SocialLogin } from "@/components/SocialLogin"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/ui/table"
import { FEES, FEES_AFTER_DISCOUNT, GST, GSTFactor } from '@/contains/contants'
import { useAppContext } from '@/context/AppContext'
import { useCartContext } from "@/context/CartContext"
import { useFirebaseContext } from "@/context/FirebaseContext"
import { $, BookingStatus, ContentApprovalStatus, DeploymentStatus, PaymentStatus } from "@/generated/zeus"
import PubSub from 'pubsub-js'
// import { adboardFragment, AdboardFragment } from '@/hooks/useAdboards'
import useUserContext from '@/hooks/useUserContext'
import useWindowSize from "@/hooks/useWindowResize"
import { mutation, useTypedLazyQuery } from "@/hooks/useZeus"
import { fireEvent, formatPrice, titleToUrl } from "@/lib/utils"
import ButtonPrimary from "@/shared/Button/ButtonPrimary"
import ButtonSecondary from '@/shared/Button/ButtonSecondary'
import ButtonClose from '@/shared/ButtonClose/ButtonClose'
import Loader from '@/shared/FullScreenLoader/Loading'
import Input from '@/shared/Input/Input'
import NcImage from "@/shared/NcImage/NcImage"
import Select from '@/shared/Select/Select'
import Textarea from '@/shared/Textarea/Textarea'
import { TrashIcon } from '@heroicons/react/24/solid'
import moment from "moment"
import { useEffect, useState } from 'react'
import { DayPickerRangeController, FocusedInputShape, isInclusivelyAfterDay } from "react-dates"
import { Helmet } from 'react-helmet'
import { useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { AssetFile } from "../CheckOutPage/types"
import AdboardMap from "../PageHome/AdboardMap"
import CampaignSummary from "./CampaignSummary"
import CreativesBox from "./CreativesBox"
import { adboardFragment, AdboardFragment } from "./types"

const adPurposes = {
  BRAND_AWARENESS: "Brand awareness",
  PRODUCT_LAUNCH: "Product launch",
  PROMOTION: "Promotion",
  SALES_AND_OFFERS: "Sales and offers",
  SERVICE_ANNOUNCEMENT: "Service announcement",
  OTHER: "Other",
}

type CampaignForm = {
  name: string
  adPurpose: keyof typeof adPurposes,
  description: string
  businessCategoryId: string,
  user:{
    name:string
    email:string
    phoneNumber:string
  }
}

export default function CampaignNew({
  className,
}: {
    className?: string
}) {
  const [searchParams] = useSearchParams();
  const {adBoards:cartAdboards} = useCartContext();
  const {loading:authLoading,user:fbUser} = useFirebaseContext()
  const [currentBox, setCurrentBox] = useState<number>(1);
  const {user} = useUserContext();
  const {register,handleSubmit,formState,setValue,getValues,} = useForm<CampaignForm>()

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  const navigate = useNavigate()
  const {businessCategories} =useAppContext()
  const [creatives,setCreatives] = useState<AssetFile[]>([])  
  const windowSize = useWindowSize();
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: null,
    endDate: null,
  })
  const [getAdboards,{data,loading}] = useTypedLazyQuery({
    adBoards:[{
      where:{
        id:{
          in: $('adBoards','[String!]')
        }
      }
    },adboardFragment]
  })

  useEffect(()=>{
    const adBoardId = searchParams.get('adboardId')
    if(adBoardId){
      getAdboards({
        variables:{
          adBoards:[adBoardId]
        }
      })
    }else if(cartAdboards.length){
      getAdboards({
        variables:{
          adBoards:cartAdboards.map(item=>item.id)
        }
      })
    }
  },[cartAdboards.length])
  
  const [selectedAdboards, setSelectedAdboards] = useState<AdboardFragment[]>([])

  useEffect(()=>{
    if(user){
      setValue('user.name',user.displayName)
      setValue('user.email',user.email)
      setValue('user.phoneNumber',user.phoneNumber)
    }
  },[user])

  useEffect(()=>{

    if(!data?.adBoards.length){
      return;
    }

    // const adBoardId = searchParams.get('adboardId')
    const startDate = searchParams.get('startDate')
    const endDate = searchParams.get('endDate')
    
    setSelectedAdboards(data.adBoards)

    setSelectedDate({
      startDate:startDate?moment(startDate):null,
      endDate:endDate?moment(endDate):null
    })
    
  },[data,cartAdboards,searchParams])

  const [priceSummary, setPriceSummary] = useState<{
    days:number,
    total:number,
    pricePerDay:number,
    gst:number,
    totalWithGST:number
  }>({
    days:0,
    total:0,
    pricePerDay:0,
    gst:0,
    totalWithGST:0
  })
  const [openCampaignSummary,setOpenCampaignSummary] = useState(false);

  useEffect(()=>{
        
    if(!selectedDate.startDate || !selectedDate.endDate){
      return
    }
    
    const days = selectedDate.endDate?.diff(selectedDate.startDate, 'days')+1;

    const totalPricePerDay = Object.values(selectedAdboards).reduce((acc:any, item) => {
      return acc+item.pricePerDay;
    },  0);
    setPriceSummary({
      days:days,
      pricePerDay:totalPricePerDay,
      total:totalPricePerDay * days,
      gst:Number(((totalPricePerDay * days) * GSTFactor).toFixed(0)),
      totalWithGST: Number(((totalPricePerDay * days) * 1.18).toFixed(0))
    })

  },[selectedAdboards,selectedDate])

  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  async function handleCreateCampaign(){
    const formData = getValues()

    if(!user?.id){
      return null;
    }
    if(Object.keys(selectedAdboards).length===0){
      toast.error('Please select at least one adboard');
      return
    }

    if(!selectedDate.endDate || !selectedDate.startDate){
      toast.error('Please select a valid date range');
      return
    }

    if(creatives.length===0){
      toast.error('Please upload at least one creative');
      return
    }

    const assetIds = await Promise.all(creatives?.map(async item => {
      return item.id
    }));

    fireEvent('begin_checkout',{
      ecommerce:{
        currency: "INR",
        value: priceSummary.totalWithGST,
        items: Object.values(selectedAdboards).map((item,i)=>({
          item_id: item.id,
          item_name: item.title,
          index: i,
          location_id: item.city,
          price: item.pricePerDay,
          quantity: 1
        }))
      }
    }) 

    const {createOneCampaignBooking} = await mutation({
      createOneCampaignBooking:[{
        data:{
          purpose: formData.adPurpose,
          assets:{
            connect:assetIds.map(id=>({id}))
          },
          name:formData.name,
          businessCategory:{
            connect:{
              id:formData.businessCategoryId
            }
          },
          priceSummary:{
            days:priceSummary.days,
            payableRent:priceSummary.total,
            rentPerDay:priceSummary.pricePerDay,
            serviceCharge:Number(priceSummary.gst.toFixed(0)),
            totalRent:Number(priceSummary.totalWithGST.toFixed(0))
          },
          paymentStatus:PaymentStatus.PENDING,
          origin: window.location.origin,
          selectedDates:{
            startDate:selectedDate.startDate,
            endDate:selectedDate.endDate
          },
          user:{
            connect:{
              uid:user?.uid
            }
          },
          bookings: {
            createMany: {
              data: Object.values(selectedAdboards).map(item => {
                const totalRent = item.pricePerDay * priceSummary.days;
                const gst = Number((totalRent * GSTFactor).toFixed(0));
                const payableRent = totalRent + gst;
                return ({
                  isPaid:false,
                  paymentStatus:PaymentStatus.PENDING,
                  purpose: formData.adPurpose,
                  priceSummary:{
                    days:priceSummary.days,
                    payableRent:payableRent,
                    rentPerDay:item.pricePerDay,
                    serviceCharge:gst,
                    totalRent:totalRent
                  },
                  workspaceId: item.workspaceId,                  
                  assetsIDs: assetIds,
                  adBoardId: item.id,
                  bookingCode: Math.random().toString(36).substring(7),
                  bookingDetails: {
                    email: user?.email || "",
                    name: user?.displayName || "",
                    phone: formData.user?.phoneNumber
                  },
                  contentApprovalStatus:ContentApprovalStatus.PENDING_REVIEW,
                  deploymentStatus: DeploymentStatus.NOT_STARTED,
                  bookingStatus: BookingStatus.ENQUIRY,
                  selectedDates: {
                    startDate: selectedDate.startDate,
                    endDate: selectedDate.endDate
                  },
                  userId: user?.id
                })
              })
            }
          }
        }
      },{
        id:true
      }]
    }).catch(e=>{
      toast.error('Failed to create campaign, Please try again later');
      throw e;
    })

    fireEvent('campaign_creation_completed',{
      currency: "INR",
      value: priceSummary.totalWithGST,
      items: Object.values(selectedAdboards).map(item=>({
        id:item.id,
        name:item.title,
        quantity:priceSummary.days,
        price:item.pricePerDay
      }))
    });

    toast.success('Campaign created successfully.')
    navigate('/pay-done-campaign/'+createOneCampaignBooking.id)

  }

  function handleOpenCampaignSummary(){
    if(creatives.length==0){
      toast.error('Please upload at least one creative');
      return
    }
    if(selectedDate.startDate==null || selectedDate.endDate==null){ 
      toast.error('Please select a valid date range');
      return;
    }
    setOpenCampaignSummary(true);
  }

  return (
    <div
      className={`nc-ListingFlightsPage relative ${className}`}
      data-nc-id="ListingFlightsPage"
    >

      {loading && <Loader fullScreen/>}
      <Helmet>
        <title>Campaigns | Adboard Booking</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative py-4">
        <div className='flex justify-between items-center' > 
          <Heading desc={user?.uid?"lets start by selecting the adboard":'lets start by logging to your account first'}>Create a new campaign</Heading>
        </div>
        
        {!fbUser?.uid ? <div className="py-48">{authLoading ? <Loader/>:<SocialLogin/>}</div>:
          <div>
            <div className='grid lg:grid-cols-12 grid-cols-1 gap-4 relative'>
              <div className='flex flex-col gap-4 col-span-7 overflow-auto h-full lg:px-2'>
                <Box title={<>
                  Adboards
                  {selectedAdboards.length>0 && <span className='text-xs text-neutral-500 dark:text-neutral-400'> {(selectedAdboards).length} adboards selected</span>}
                </>} classname='flex flex-col gap-2' collapsible collapsed={currentBox!=1}  onCollapseChange={(collapsible)=>{
                  !collapsible && setCurrentBox(1)
                }}> 
                  <Table className=''>
                    <TableBody className=''>
                      {selectedAdboards.map((item)=>(  
                        <TableRow key="user1">
                          <TableCell colSpan={4}>
                            <div>
                              <div className="flex items-center gap-4">
                                <NcImage src={item.galleryImgs[0]+'-webimage'} className="w-20 object-cover rounded-lg" />
                                <div className="flex-1 flex flex-col gap-2 justify-between h-full">
                                  <Link target="_blank" to={`/hoardings/${titleToUrl(item.title)}/${item.id}`} className="flex text-lg items-center gap-2">
                                    {item.title}
                                  </Link>
                                  <div className="flex gap-2 flex-col">

                                    <div className="flex items-start gap-2">
                                      <span className="text-sm text-neutral-500 dark:text-neutral-400">City:</span>
                                      <span>{item.city}</span>
                                    </div>
                                    
                                    <div className="flex items-start gap-2">
                                      <span className="text-sm text-neutral-500 dark:text-neutral-400">Price per day:</span>
                                      <span>{formatPrice(item.pricePerDay)}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <TrashIcon className="cursor-pointer text-purple-800" width={'20'} onClick={()=>{
                                    setSelectedAdboards(selectedAdboards.filter(adboard=>adboard.id!=item.id));
                                  }}/>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="mb-2">
                    <ButtonSecondary onClick={()=>{
                      PubSub.publish('START_SEARCH_CLICK_3',null)
                    }}>
                    Add more adboards
                    </ButtonSecondary>
                    &nbsp;
                    <ButtonPrimary onClick={()=>setCurrentBox(2)}>Next</ButtonPrimary>
                  </div>
                </Box>
                <Box title={<>
                  Campaign details
                  {(formState.errors.name || formState.errors.adPurpose || formState.errors.businessCategoryId) && <span className='text-xs text-red-500 dark:text-error-400'> * Missing</span>}
                </>} collapsible collapsed={currentBox!=2} onCollapseChange={(collapsible)=>{
                  !collapsible && setCurrentBox(2)
                }}>
                  <div className='flex flex-col gap-2'>
                    <Input label="Campaign name" type="text" {...register('name',{required:true})} aria-invalid={formState.errors.name ? "true" : "false"}/>
                    {formState.errors.name && <div className='text-red-500 text-sm'>Campaign name is required</div>}
                    <Textarea label='Brief detail about your campaign' {...register('description')}/>
                    <Select label="Business category" placeholder='Select business category' {...register('businessCategoryId',{required:true})}>
                      <option value="">Select business category</option>
                      {businessCategories.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))} 
                    </Select>
                    {formState.errors.businessCategoryId && <div className='text-red-500 text-sm'>Ad category is required</div>}
                    <Select label="Advertisement purpose" placeholder='Select category' {...register('adPurpose',{required:true})}>
                      <option value="">Select purpose</option>
                      {Object.keys(adPurposes).map((item) => (
                        <option value={item}>{adPurposes[item]}</option>
                      ))}
                    </Select>
                    {formState.errors.adPurpose && <div className='text-red-500 text-sm'>Ad purpose is required</div>}
                  </div>
                  <div className="mt-4 mb-2 gap-2 flex">
                    <ButtonSecondary onClick={()=>setCurrentBox(1)}>Back</ButtonSecondary>
                    <ButtonPrimary onClick={()=>{
                      setCurrentBox(3)
                    }}>Next</ButtonPrimary>
                  </div>
                </Box>               
               
                <Box title={<>
                  User details
                  {(formState.errors.user?.phoneNumber) && <span className='text-xs text-red-500 dark:text-error-400'> * Missing</span>}
                </>} collapsible  collapsed={currentBox!=3} onCollapseChange={(collapsible)=>{
                  !collapsible && setCurrentBox(3)
                }}>
                  <div className='flex flex-col gap-2'>
                    <Input type="text" label="Name" placeholder='Name' {...register('user.name',{required:true})} aria-invalid={formState.errors.name ? "true" : "false"}/>
                    {formState.errors.user?.name && <div className='text-red-500 text-sm'>Name is required</div>}
                    <Input label="Email" type="email" placeholder='Email' {...register('user.email',{
                      required:true,
                      pattern:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                    })} aria-invalid={formState.errors.name ? "true" : "false"}/>
                    {formState.errors.user?.email && <div className='text-red-500 text-sm'>Email is required</div>}
                    <Input label="Phone number" type="text" 
                      {...register('user.phoneNumber',{
                        required:true,
                        pattern:/^(\+91[\-\s]?)?[6-9]\d{9}$/})
                      } />
                    {formState.errors.user?.phoneNumber && <div className='text-red-500 text-sm'>Valid phone number is required</div>}
                  </div>
                  <div className="mt-4 mb-2 gap-2 flex">
                    <ButtonSecondary onClick={()=>setCurrentBox(2)}>Back</ButtonSecondary>
                    <ButtonPrimary onClick={()=>setCurrentBox(4)}>Next</ButtonPrimary>
                  </div>
                </Box>               
               
                <Box title="Schedule" collapsible  collapsed={currentBox!=4} onCollapseChange={(collapsible)=>{
                  !collapsible && setCurrentBox(4)
                }}>
                  <div className="listingSection__wrap__DayPickerRangeController flow-root mx-auto">
                    <div className="flex justify-center">
                      <DayPickerRangeController
                        noBorder
                        minimumNights={0}
                        startDate={selectedDate.startDate}
                        endDate={selectedDate.endDate}
                        onDatesChange={(date) => {
                          setSelectedDate({
                            startDate: date.startDate,
                            endDate: date.endDate||date.startDate,
                          });
                        }}
                        isOutsideRange={(day) => {
                          return !isInclusivelyAfterDay(day, moment())
                        }}
                        focusedInput={focusedInputSectionCheckDate}
                        onFocusChange={(focusedInput) =>
                          setFocusedInputSectionCheckDate(focusedInput || "startDate")
                        }
                        numberOfMonths={windowSize.width < 1280 ? 1 : 2}
                        initialVisibleMonth={null}
                        hideKeyboardShortcutsPanel={false}
                       
                      />
                    </div>
                   
                    <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                      <div className="flex-1 p-5 flex space-x-4">
                        <svg
                          className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                            stroke="#D1D5DB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <div className="flex flex-col">
                          <span className="text-sm text-neutral-400">Selected dates</span>
                          <span className="mt-1.5 text-lg font-semibold">
                            {moment(selectedDate?.startDate).format('LL')} - {selectedDate?.endDate? moment(selectedDate?.endDate).format('LL'):""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 gap-2 mb-2 flex">
                    <ButtonSecondary onClick={()=>setCurrentBox(3)}>Back</ButtonSecondary>
                    <ButtonPrimary onClick={()=>setCurrentBox(5)}>Next</ButtonPrimary>
                  </div>
                </Box>
                <Box title={<>
                  Creatives
                  {(creatives.length==0 && formState.dirtyFields.name) && <span className='text-xs text-red-500 dark:text-error-400'> * Missing</span>}
                </>} collapsible collapsed={currentBox!=5} onCollapseChange={(collapsible)=>{
                  !collapsible && setCurrentBox(5)
                }}>
                  <CreativesBox selectedAdboards={Object.values(selectedAdboards)} creatives={creatives} setCreatives={setCreatives} />
                  <div className="mt-4 mb-2 gap-2 flex">
                    <ButtonSecondary onClick={()=>setCurrentBox(4)}>Back</ButtonSecondary>
                    <ButtonPrimary onClick={()=>setCurrentBox(6)}>Next</ButtonPrimary>
                  </div>
                </Box>

                <Box title="Price summary" collapsible collapsed={currentBox!=6} onCollapseChange={(collapsible)=>{
                  !collapsible && setCurrentBox(6)
                }}>
                  <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>₹{priceSummary.pricePerDay} x {priceSummary.days} days</span>
                      <span>₹{priceSummary.total}</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>GST (@{GST})</span>
                      <span>₹{priceSummary.gst}</span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                      <span>Total</span>
                      <span>₹{priceSummary.totalWithGST}</span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>Payable now (platform fees)</span>
                      <span>₹{FEES_AFTER_DISCOUNT} <s>₹{FEES}</s></span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>Payable on booking approval</span>
                      <span>₹{priceSummary.totalWithGST}</span>
                    </div>
                    
                  </div>
                  <div className="mt-4 gap-2 mb-2 flex">
                    <ButtonSecondary onClick={()=>setCurrentBox(5)}>Back</ButtonSecondary>
                    {/* <ButtonPrimary onClick={()=>setCurrentBox(6)}>Next</ButtonPrimary> */}
                  </div>
                </Box>

                <div className='flex flex-col justify-end gap-4 mt-2'>
                  <div> 
                    <p className='text-xs mt-4'>
            By selecting the button below, I agree to the <a className='text-blue-800' href="https://www.adboardbooking.com/cancellation.html" target="_blank">Adboard Booking Rules, and Refund Policy</a> and that Adboard Booking can charge my payment method if I’m responsible for damage. I agree to pay the total amount shown if the Host accepts my booking request.
                    </p>
                  </div>
                  <div className=''>
                    <b>
        You will not be charged until your booking is approved.
                    </b>
                  </div>
                  <div className="flex gap-2 items-end">
                    <ButtonPrimary onClick={handleSubmit(handleOpenCampaignSummary)} className="whitespace-nowrap">Review campaign</ButtonPrimary>
                    <DialogBody isOpen={openCampaignSummary} 
                      title="Campaign summary"
                      setOpen={setOpenCampaignSummary}>
                      <CampaignSummary 
                        selectedAdboards={Object.values(selectedAdboards)} 
                        selectedDate={selectedDate} 
                        priceSummary={priceSummary} 
                        creatives={creatives}
                        onClick={handleSubmit(handleCreateCampaign)}
                      />
                    </DialogBody> 
                  </div>
                  <span className="text-xs">
                    Clicking above button will create a campaign and you will be redirected to the campaign page. Upon approval of the campaign, you will be charged the total amount shown above.
                  </span>
                </div>
              
                <div className='mb-2'></div>
              </div>
              {!showFullMapFixed && (
                <div
                  className="flex  xl:hidden items-center justify-center fixed bottom-16 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
                  onClick={() => setShowFullMapFixed(true)}
                >
                  <i className="text-lg las la-map"></i>
                  <span className="whitespace-nowrap">Select on map</span>
                </div>
              )}
              
              <div className={`xl:flex-grow xl:static xl:block col-span-5 ${
                showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
              }`}>
                {showFullMapFixed && (
                  <ButtonClose
                    onClick={() => setShowFullMapFixed(false)}
                    className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                  />
                )}
                <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">

                  <Box>
                    
                    {/* <i className="text-xs">
                      Navigate the map to find the adboards you want to add to your campaign. <br />
                      Clicking a blue market will add it your campaign, Black markers are already added.
                    
                    </i> */}
                    <div
                      className={`xl:flex-grow xl:static xl:block ${
                        showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
                      }`}
                    >
                      {showFullMapFixed && (
                        <ButtonClose
                          onClick={() => setShowFullMapFixed(false)}
                          className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                        />
                      )}

                      <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full lg:h-[600px] rounded-md overflow-hidden">
                       
                        {data?.adBoards.length ? 
                          <AdboardMap pois={(selectedAdboards).map(item=>item.players).flat().map(item=>{
                            return {
                              key: item.id,
                              location:{
                                lat: item.map?.lat||0,
                                lng: item.map?.lng||0
                              }
                            }
                          })||[]} ></AdboardMap>
                          :<></>}
                  
                      </div>
                    </div>
                    
                  </Box>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
      
  )
}
