import Label from "@/components/Label/Label";
import useUserContext from "@/hooks/useUserContext";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Avatar from "@/shared/Avatar/Avatar";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import Input from "@/shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { mutation, useTypedMutation } from "@/hooks/useZeus";
import { $, NotificationChannel } from "@/generated/zeus";
import Box from "@/components/Box";
import Checkbox from "@/shared/Checkbox/Checkbox";
import Loader from "@/shared/FullScreenLoader/Loading";

export interface AccountPageProps {
  className?: string;
}

type Inputs={
  displayName:string,
  email:string,
  phoneNumber:string,
  password:string,
  confirmPassworkd:string,
  notifications:{
    push:boolean,
    email:boolean
  },
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const {user} = useUserContext();

  const [updateProfile,{loading}] = useTypedMutation({
    updateUser:[{
      accountDetails:$('accountDetails','AccountDetails!')
    },{
      id:true
    }]
  })
  
  const { register, handleSubmit,setValue,getValues ,watch} = useForm<Inputs>();

  useEffect(()=>{
    if(user){
      setValue('displayName',user.displayName)
      setValue('email',user.email)
      setValue('phoneNumber',user.phoneNumber)
      setValue('notifications.email',user.notificationPreference?.channels?.includes(NotificationChannel.EMAIL) || false)
      setValue('notifications.push',user.notificationPreference?.channels?.includes(NotificationChannel.PUSH) || false)
    }
  },[user])
  
  const handleUpdate: SubmitHandler<Inputs> = async data => {

    if(data.password != data.confirmPassworkd){
      return toast.error('Passwords do not match')
    }

    updateProfile({
      variables:{
        accountDetails:{
          password:data.password,
          phoneNumber:data.phoneNumber,
          displayName:data.displayName,
        }
      }
    }).then(()=>{
      toast.success('Profile updated successfully')
    }).catch(e=>{
      toast.error(e.message)
    })
  }

  async function saveNotificaitons(){

    if(!user) return
    const formData = getValues()

    const preferences:NotificationChannel[]=[]
    if(formData.notifications.email){
      preferences.push(NotificationChannel.EMAIL)
    }
    if(formData.notifications.push){
      preferences.push(NotificationChannel.PUSH)
    }

    await mutation({
      updateOneUser:[{
        where:{
          id:user.id
        },
        data:{
          notificationPreference:{
            enabled:preferences.length>0,
            channels:preferences
          }
        }
      },{
        notificationPreference:{
          enabled:true,
          channels:true
        }
      }]
    })
  }

  if(!user){
    return <Loader/>  
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account | AdBoard Booking</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <Box title="Profile information">
            <div className="flex flex-col md:flex-row">
              <div className="flex-shrink-0 flex items-start">
                <div className="relative rounded-full overflow-hidden flex">
                  <Avatar sizeClass="w-32 h-32" imgUrl={user.image}/>
                  <input
                    type="file"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                <div>
                  <Label>Name</Label>
                  <Input readOnly className="mt-1.5 " {...register('displayName',{
                    value:user.displayName,
                  })} disabled={true} />
                </div>
                <div>
                  <Label>Email</Label>
                  <Input readOnly className="mt-1.5" {...register('email',{
                    value:user.email,
                  })} disabled={true} />
                </div>
                <div>
                  <Label>Phone number</Label>
                  <Input placeholder="Phone number (+919999999999)"
                    {...register('phoneNumber',{
                      value:user.phoneNumber,
                    })} className="mt-1.5"/>
                </div>
                <div className="pt-2">
                  <ButtonPrimary loading={loading} onClick={handleSubmit(handleUpdate)}>Update info</ButtonPrimary>
                </div>
              </div> 
            </div>
          </Box>
          <Box title="Notification preferences" classname="p-2">
            <div className="flex flex-col gap-4 pt-4">
              <Checkbox 
                name="email" 
                label="Email" 
                checked={watch('notifications.email')}
                onChange={(value)=>{
                  setValue('notifications.email',value)
                }}></Checkbox>
              
              <Checkbox 
                name="push"
                checked={watch('notifications.push')}
                label="Push" onChange={(value)=>{
                  setValue('notifications.push',value)
                }}></Checkbox>
            </div>
            <div className="pt-8">
              <ButtonPrimary loading={loading} onClick={saveNotificaitons}>Save</ButtonPrimary>
            </div>
          </Box>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
