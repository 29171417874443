import { Rating } from 'flowbite-react'
import React from 'react'

export default function Ratings({
  rating,
  onChange
}:{
    rating?:number
    onChange?:(rating:number)=>void
}) {
  const [currentRating,setCurrentRating] = React.useState(rating||0)

  function handleClick(index){
    setCurrentRating(index+1)
    onChange?.(index+1)
  }

  return (
    <div className="flex items-center">
      <Rating>
        {Array(5).fill(0).map((_,i)=><Rating.Star key={i} filled={currentRating>=i+1} onClick={()=>handleClick(i)} className='cursor-pointer'/>)}    
      </Rating>
      <span className="ms-2 text-lg font-bold text-gray-900 dark:text-white">{currentRating} out of 5</span>
    </div>
  )
}
