import BtnLikeIcon from "@/components/BtnLikeIcon/BtnLikeIcon";
import GallerySlider from "@/components/GallerySlider/GallerySlider";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Badge from "@/shared/Badge/Badge";
import { capitalize } from "lodash";
// import { AdboardFragment } from "@/hooks/useAdboards";
import { titleToUrl } from "@/lib/utils";
import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";

export const adBoardSelector = Selector('AdBoard')({
  galleryImgs: true,
  type: [{}, {
    name: true
  }],
  title: true,
  screenSize:{
    width:true,
    height:true,
    unit:true
  },
  resolution:{
    width:true,
    height:true
  },
  address: true,
  offlineBookingsEnabled: true, 
  city: true,
  originalPricePerDay: true,
  pricePerDay: true,
  id: true,
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adBoardSelector>;

export interface PropertyCardHProps {
  className?: string;
  data?: AdboardFragment;
}

const PropertyCardH: FC<PropertyCardHProps> = ({
  className = "",
  data,
}) => {
  const {
    galleryImgs,
    type,
    originalPricePerDay,
    pricePerDay:price,
    id,

  } = data||{};

  const navigate = useNavigate();

  const {
    name:screenTypeName
  } = type||{};

  if(!data){
    return null;
  }

  const renderSliderGallery = () => {
    const discount = originalPricePerDay ? ((-originalPricePerDay + (price||0))*100/originalPricePerDay) : 0;
    
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          saleOffBadge={discount?`${discount.toFixed(0)}% off today`:""}
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={galleryImgs||[]}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
          href={"/hoardings/"+titleToUrl(data.title)+"/"+data.id+window.location.search}
        />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-1 gap-2 w-full">
        <div className="flex justify-between w-full">
          <div className="flex items-center space-x-2">
            <span className="hidden sm:inline-block">
              <i className="las la-arrows-alt text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {data.screenSize?.width} {data.screenSize?.unit} x {data.screenSize?.height} {data.screenSize?.unit}
            </span>
          </div>

          {/* ---- */}
          <div className="flex items-center space-x-2">
            <span className="hidden sm:inline-block">
              <i className="las la-expand text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {data.resolution?.width}x{data.resolution?.height}
            </span>
          </div>
        </div>
        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-map"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data.address}
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-2 w-full relative">
          {!data.offlineBookingsEnabled &&
          <div className="inline-flex space-x-3 absolute -top-4">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">Online</span>
                </div>
              }
            />
          </div>
          }
          <span className="line-clamp-2 text-sm"><span >{capitalize(screenTypeName?.toLocaleLowerCase())}</span> in {data.city}</span>
          <div className="flex items-center">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className="text-lg font-medium">
              <span className="line-clamp-2">{data.title}</span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
            ₹{originalPricePerDay && <><s>{`${originalPricePerDay}`}</s> &nbsp;</>}{`${price}`} per day
            </span>
          </div>
        </div>
      </div>
    );
  };

  function handleClick(){
    navigate("/hoardings/"+data?.id+window.location.search);
  }

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden cursor-pointer hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
      onClick={handleClick}
    >
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      />
    </div>
  );
};

export default PropertyCardH;
