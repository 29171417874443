import React, { FC } from "react";
import StartRating from "@/components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "@/components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "@/components/SaleOffBadge/SaleOffBadge";
import Badge from "@/shared/Badge/Badge";
import LocationPointerIcon from "../icon/LocationPointerIcon";
import { formatPrice, titleToUrl } from "@/lib/utils";
import AssetPreview from "../AssetPreview";
import { useWishlistContext } from "@/context/WishlistProvider";
import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";

export const adboardFragment = Selector('AdBoard')({
  galleryImgs:true,
  title:true,
  originalPricePerDay:true,
  id:true,
  pricePerDay:true,
  adsPerLoop:true,
  adsDuration:true,
  city:true,
  type:[{},{
    name:true
  }],
  players:[{},{
    id:true
  }]
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adboardFragment>;

export interface StayCardProps {
  className?: string;
  data?: AdboardFragment,
  section?:string,
  target?: string;
  skipNavigation?: boolean;
  size?: "default" | "small";
}

export const StayCard: FC<StayCardProps> = ({
  size = "default",
  section,
  className = "",
  skipNavigation,
  target,
  data,
}) => {
  const {
    galleryImgs,
    title,
  } = data||{};

  const {wishlistedAdBoardIds,toggleAdboardInWishlist} = useWishlistContext();
  if(!data){
    return <></>;
  }

  const isAds=false;

  const discount = (data.originalPricePerDay||0)-(data.pricePerDay||0)
  const reviewStart = false;
  
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full h-60 overflow-hidden">
        <Link to={skipNavigation?"":'/hoardings/'+titleToUrl(data.title)+'/'+data.id+window.location.search}>
          <AssetPreview
            alt={title}
            url={galleryImgs?.[0]+"-webimage"}
            className="object-cover h-full w-full"
          />
        </Link>
        <BtnLikeIcon toggleAdboardInWishlist={()=>toggleAdboardInWishlist(data.id)} isLiked={wishlistedAdBoardIds.includes(data.id)} className="absolute right-3 top-3 z-[1]" />
        {discount>0 && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {data.type?.name} · {data.players.length||1} screens
          </span>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
           
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {(size === "default"||size=='small') && (
              <LocationPointerIcon/>
            )}
            <span className="">{data.city}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {formatPrice(data.pricePerDay)}
            {` `}
            {(size === "default" || size === "small") && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /day
              </span>
            )}
          </span>
          {!!reviewStart && (
            <StartRating reviewCount={0} point={reviewStart} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-gray-300 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
      onClick={e=>e.stopPropagation()}
    >
      {renderSliderGallery()}
      <Link target={target||'_blank'} to={!skipNavigation ? '/hoardings/'+titleToUrl(data.title)+"/"+data.id+window.location.search+"#section="+section:""}>
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCard;
