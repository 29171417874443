import { Dialog } from '@/components/Dialog/Dialog'
import React from 'react'
import ButtonSecondary from '@/shared/Button/ButtonSecondary'
import FileManager from './FileManager'
import { UploadedFilesFragmentFragment } from '@/type-gql/graphql'
import { AssetFile } from './types'

export type UploadedFile = UploadedFilesFragmentFragment

export default function FileChooserDialog({ onFileSelect,children,video }: { video?:boolean, onFileSelect: (file: AssetFile) => void,children?:React.ReactNode }) {
  return (
    <Dialog header="Asset Manager" >
      <Dialog.Trigger>
        {children || <ButtonSecondary>Recent uploads</ ButtonSecondary>}
      </Dialog.Trigger>
      <Dialog.Body>
        {({closeDialog}:any)=><FileManager video={video} hideUploadBtn={false} onFileSelect={(file:AssetFile)=>{
          onFileSelect(file)
          closeDialog()
        }}/>}
      </Dialog.Body>
    </Dialog>
  )
}
