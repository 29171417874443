
import React, { useState, useRef, useEffect } from 'react';
import { AdvancedMarker, Map, Pin, useMap } from '@vis.gl/react-google-maps';
import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';

type Poi ={ key: string, location: google.maps.LatLngLiteral }

export const PoiMarkers = (props: { pois: Poi[],highlightedKeys?:string[] }) => {
  const map = useMap();
  const [markers, setMarkers] = useState<{[key: string]: Marker}>({});
  const clusterer = useRef<MarkerClusterer | null>(null);
  const markersRef = useRef<google.maps.Marker[]>([]);
  const clustererRef = useRef<MarkerClusterer | null>(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const pois = props.pois;

  useEffect(() => {
    if (!map) return;

    // Clear existing markers from the map
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    // Create new markers
    const newMarkers = pois.map(poi => {
      const marker = new google.maps.Marker({
        position: poi.location,
        title: poi.key,
      });
      marker.addListener('click', () => {
        // Handle marker click if needed
        console.log('Marker clicked:', poi);
      });
      return marker;
    });

    // Update markers reference
    markersRef.current = newMarkers;

    // Initialize or update the MarkerClusterer
    if (clustererRef.current) {
      clustererRef.current.clearMarkers();
      clustererRef.current.addMarkers(newMarkers);
    } else {
      clustererRef.current = new MarkerClusterer({ map, markers: newMarkers });
    }
    clustererRef.current.clearMarkers();
    
  }, [map, pois]);

  useEffect(()=>{
    // Adjust map bounds to fit all markers
    if (markersRef.current.length > 0 && !hasLoaded) {
      setHasLoaded(true);
      const bounds = new google.maps.LatLngBounds();
      markersRef.current.forEach(marker => bounds.extend(marker.getPosition()!));
      map?.fitBounds(bounds);
    }
  },[pois])

  // Update markers, if the markers array has changed
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers(prev => {
      if (marker) {
        return {...prev, [key]: marker};
      } else {
        const newMarkers = {...prev};
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      {props.pois.map( (poi: Poi) => {
        const isHighlighted = props.highlightedKeys?.includes(poi.key);
        return (
          <AdvancedMarker
            key={poi.key}
            position={poi.location}
            ref={marker => setMarkerRef(marker, poi.key)}
          >
            <Pin glyph={"A"} scale={isHighlighted?1:0.8} background={!isHighlighted?'#4f46e5':'#000'} glyphColor={'#fff'} borderColor={'#fff'} />
          </AdvancedMarker>
        )})}
    </>
  );
};

export default function AdboardMap({
  pois,
  id,
  highlightedKeys
}:{
    id?: string,
    pois: Poi[],
    highlightedKeys?:string[]
}) {

  return (
    <Map
      defaultZoom={13}
      mapId={id||'DEMO_MAP_ID'}
      defaultCenter={ pois[0]?.location }
    >
      <PoiMarkers highlightedKeys={highlightedKeys} pois={pois}/>
    </Map>
  )
}
