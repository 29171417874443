import AssetPreview from '@/components/AssetPreview'
import Box from '@/components/Box'
import React from 'react'
import { Badge } from "@/components/ui/badge";
import { getFileSize } from '@/lib/utils';

export default function AssetBox({
  creative
}:{
    creative: {
        meta:{
            width: number;
            height: number
        },
        id: string;
        name: string;
        url: string;
        type: string;
        size: number;
    }   
}) {
  return (
    <Box key={creative.id}>
      <div className="flex items-start gap-4">
        <div className="bg-gray-100 p-3 rounded">
          <div className="w-48 text-gray-600">
            <AssetPreview url={creative.url+"-webimage"} />
          </div>
        </div>
        <div className="flex-1">
          <h3 className="font-semibold">{creative.name}</h3>
          <p className="text-sm text-gray-600">
            {creative.type} • {getFileSize(creative.size)}
          </p>
          <div className="mt-2">
            <Badge variant="outline" className="bg-green-50">
              {creative.meta.width +"x"+ creative.meta.height}
            </Badge>
          </div>
        </div>
      </div>
    </Box>
  )
}
