import React from "react";
import { Link } from "react-router-dom";
import logoImg from "@/images/logos/new/AdBoardLogo/logo1.png";
import logoLightImg from "@/images/logos/new/AdBoardLogo/logo2.png";
import { useWhiteLabel } from "@/context/WhiteLabelProvider";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  icon?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-48",
  icon=false,
}) => {

  const {whiteLabelKey,whitelabelWorkspace} = useWhiteLabel();

  function AdboardLogo(){
    return <>
      {img ? (
        
        <img
          className={`block ${imgLight ? "dark:hidden" : ""}`}
          src={icon?"https://adboard-booking-cdn.mos.ap-southeast-2.sufybkt.com/adboard-booking-web/AdBoardLogo/icon3.png-webimage":"https://adboard-booking-cdn.mos.ap-southeast-2.sufybkt.com/adboard-booking-web/AdBoardLogo/logo1.png-webimage"}
          alt="Adboard Booking logo"
        />
      ) : (
        "Adboard Booking"
      )}
      {imgLight && (
        <img
          className="hidden dark:block"
          src={icon?"https://adboard-booking-cdn.mos.ap-southeast-2.sufybkt.com/adboard-booking-web/AdBoardLogo/icon2.png-webimage":"https://adboard-booking-cdn.mos.ap-southeast-2.sufybkt.com/adboard-booking-web/AdBoardLogo/logo2.png-webimage"}
          alt="Adboard Booking logo"
        />
      )}
    </>
  }

  function WhiteLabelLogo(){
    return <>
      <img
        className={`w-10 h-10 rounded-full block ${imgLight ? "dark:hidden" : ""}`}
        src={whitelabelWorkspace?.avatar}
        alt={whitelabelWorkspace?.name}
      />
    </>
  }

  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {whiteLabelKey ? <WhiteLabelLogo/>:
        <AdboardLogo/>
      }
    </Link>
  );
};

export default Logo;
