import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";
import NcImage from "@/shared/NcImage/NcImage";
import { FC } from "react";
import { Link } from "react-router-dom";

export interface CarCardProps {
  className?: string;
  data?: AdboardFragment;
  size?: "default" | "small";
}

export const adBoardSelector = Selector('AdBoard')({
  id:true,
  title:true,
  city:true,
  adsDuration:true,
  adsPerLoop:true,
  pricePerDay:true,
  galleryImgs:true,
  players:[{},{
    id:true,
    map:{
      lat:  true,
      lng:  true,
    }
  }]
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adBoardSelector>;

const CarCard: FC<CarCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {

  if(!data){
    return null;
  }

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <div className="aspect-w-16 aspect-h-9 ">
          <NcImage
            containerClassName="flex items-center justify-center"
            className="w-full"
            src={data?.galleryImgs[0]}
          />
        </div>
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {!!saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={`  capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span className="line-clamp-1">{data.title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="">
              <i className="las la-map-marker-alt"></i>
              {data.city}</span>
            <span>-</span>
            <span className="">{data.players.length} screens</span>
          </div>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {data.pricePerDay}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /day
              </span>
            )}
          </span>
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >
      <Link to={`/hoardings/${data.title}/${data.id}`} className="flex flex-col">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default CarCard;
