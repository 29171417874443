import { BookingFragment } from "@/containers/Bookings/types";
import { BookingStatus } from "@/generated/zeus";
import moment from "moment";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import StatusBadge from "@/shared/Badge/StatusBadge";
import { formatPrice } from "@/lib/utils";

export interface FlightCardProps {
  refresh?: () => void;
  className?: string;
  data?:BookingFragment
}

const BookingCard: FC<FlightCardProps> = ({ className = "", data:booking }) => {

  const [isOpen] = useState(false);

  const {id,selectedDates} = booking||{}

  const startDate = moment(selectedDates?.startDate as string)
  const endDate = moment(selectedDates?.endDate as string)

  return (
    <Link to={booking?.bookingStatus!= BookingStatus.PAYMENT_PENDING ? "/booking/" + id:'/bookings'} className="cursor-pointer">
      <div
        className={`nc-FlightCardgroup  p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-gray-300
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
        data-nc-id="FlightCard"
      >
        <div
          className={` sm:pr-20 relative ${className} ${booking?.bookingStatus != BookingStatus.PAYMENT_PENDING ? "cursor-pointer" : ""}`}
          data-nc-id="FlightCard"
        // onClick={() => setIsOpen(!isOpen)}
        >
          {booking?.bookingStatus != BookingStatus.PAYMENT_PENDING && 
          <span
            className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
            }`}
          // onClick={() => setIsOpen(!isOpen)}
          >
            <i className="text-xl las la-angle-right"></i>
          </span>
          }

          <div className="flex flex-col gap-2 lg:grid lg:grid-cols-6">
            {/* LOGO IMG */}
            <div className="lg:w-32 flex-shrink-0">
              <div className="flex-shrink-0 w-full sm:w-32 rounded-md overflow-hidden">
                {booking?.assets[0] && <div>
                  {booking.assets[0].type.startsWith('image') ? (
                    <img src={booking.assets[0].url} alt={booking.adBoard?.title} className="w-full h-full object-cover" />
                  ) : (
                    <video className="w-full h-full object-cover" controls>
                      <source src={booking.assets[0].url} type={booking.assets[0].type} />
                    </video>
                  )
                  }
                </div>}
              </div>
            </div>

            {/* FOR MOBILE RESPONSIVE */}
            <div className="block lg:hidden space-y-1">
              <div className="inline-block top-4 ">
                <StatusBadge name={booking?.bookingStatus || ""}></StatusBadge>
              </div>
              <div className="flex font-semibold flex-col">
                <div>
                  <span>{startDate.format('LL')}</span>
                </div>
                <span className="w-12 flex justify-center">
                  <i className=" text-2xl las la-long-arrow-alt-down"></i>
                </span>
                <div>
                  <span>{endDate.format('LL')}</span>
                </div>
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {moment(endDate).diff(moment(startDate), 'days') + 1} Days
              </div>

            </div>

            {/* TIME - NAME */}
            <div className="hidden lg:block  min-w-[150px] flex-[4] ">
              <div className="font-medium text-lg">{moment(startDate).format('DD MMM')} - {moment(endDate).format('DD MMM')}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {moment(endDate).diff(moment(startDate), 'days') + 1} Days
              </div>
            </div>

            {/* TIME */}
            <div className="hidden lg:block flex-[4] whitespace-nowrap col-span-3">
              <div className="font-medium text-lg flex">
                <div>
                  {booking?.adBoard?.title} &nbsp; <StatusBadge name={booking?.bookingStatus}>{booking?.bookingStatus}</StatusBadge>
                </div>

              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {booking?.adBoard?.city}
              </div>
            </div>
            
            {/* PRICE */}
            <div className="flex-[4] whitespace-nowrap sm:text-right">
              <div>
                <span className="text-xl font-semibold text-secondary-6000">
                  {formatPrice(booking?.priceSummary?.totalRent)}
                </span>
              </div>
              <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                {formatPrice(booking?.adBoard?.pricePerDay)}/day
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BookingCard;
