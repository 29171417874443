import { AdBoardScalarFieldEnum, WorkspaceType } from '@/generated/zeus';
import { apiFetch } from '@/hooks/fetch';
import useUserContext from '@/hooks/useUserContext';
import { useTypedQuery } from '@/hooks/useZeus';
import React,{useEffect, useRef} from 'react'
import LoadingBar from 'react-top-loading-bar'
import { useWhiteLabel } from './WhiteLabelProvider';

type LoadingBar={
  staticStart:()=>void;
  complete:()=>void;
  continuousStart:()=>void;
}

type LoadingBarRef={
  current:LoadingBar|null
}

type AppContextProps={
  pageViews:Record<string,string>,
  getPageViews:(adBoardId?:string)=>number,
  show: boolean;
  cities:{
    city:string;
    _count?:{
      city:number
    }
  }[];
  adBoardTypes:{
    id:string;
    name:string;
  }[];
  businessCategories:{
    id:string;
    name:string;
  }[];
  loadingBarRef: LoadingBarRef
}

export const AppContext = React.createContext<AppContextProps>({} as AppContextProps);

export const AppProvider = ({children}:React.PropsWithChildren<{}>) => {
  const ref = useRef<LoadingBar>(null)
  const [pageViews,setPageViews] = React.useState<Record<string,string>>({})  
  const {config} = useUserContext()
  const {whiteLabelKey} = useWhiteLabel();

  const {data} = useTypedQuery({
    adBoardTypes:[{
      where:whiteLabelKey ? {
        adBoards:{
          every:{
            workspace:{
              is:{
                key: {
                  equals:whiteLabelKey,
                }
              }
            }
          }
        }
      }:undefined
    },{
      id:true,
      name:true
    }],
    businessCategories:[{},{
      id:true,
      name:true
    }],
    advertisementCategories:[{

    },{
      id:true,
      name:true,
    }],
    groupByAdBoard:[{
      by:[AdBoardScalarFieldEnum.city],
      where:{
        
        city:{
          not:{
            in: ['']
          }
        },
        offlineBookingsEnabled:{
          equals:false
        },
        isLive:{
          equals:true
        },
        workspace:{
          is:{
            key: whiteLabelKey ? {
              equals:whiteLabelKey,
            }:undefined,
            type:{
              in:[config?.env == 'production' ? WorkspaceType.PROD : WorkspaceType.TEST] 
            }
          }
        }
      }
    },{
      city:true,
      _count:{
        city:true
      }
    }]
  },{
    fetchPolicy:'cache-first'
  })

  useEffect(()=>{
    apiFetch('/api/analytics/pageViews')
      .then(res=>res.json())
      .then((data)=>{
        setPageViews(data.pageViews)
      });
  },[])

  function getPageViews(adBoardId?:string){
    if(!adBoardId) return 0;
    return Object.keys(pageViews).filter((key)=>key.includes(adBoardId)).reduce((a,b)=>a+parseInt(pageViews[b]),0)
  }
  
  return (
    <AppContext.Provider value={{
      show:false,
      pageViews,
      getPageViews,
      loadingBarRef:ref,
      adBoardTypes:data?.adBoardTypes||[],
      businessCategories:data?.businessCategories||[],
      cities:[{
        city:'All',
        _count:{
          city:data?.groupByAdBoard?.reduce((a,b)=>a+(b._count?.city||0),0)||0
        }
      },...data?.groupByAdBoard||[]].sort((a,b)=>(b._count?.city||0)-(a._count?.city||0)),
    }}>
      <LoadingBar color="#5046e4" 
        height={4}
        background='5'
        ref={ref} shadow={true} />
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => React.useContext(AppContext);
export const useLoadingBar = () => React.useContext(AppContext).loadingBarRef.current;